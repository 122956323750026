import React, {useCallback, useContext, useEffect, useState} from 'react'

import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js'
import {Form, Formik, FormikProps} from 'formik'
import FormikErrorFocus from 'formik-error-focus'
import {
  Button,
  Card,
  Col,
  Container,
  Form as BootstrapForm,
  Row,
} from 'react-bootstrap'
import {Link, useHistory} from 'react-router-dom'
import * as Yup from 'yup'

import {useAuth} from 'auth'
import Content from 'components/Content'
import SpinnerButton from 'components/SpinnerButton'
import {AuthenticateRequest} from 'models/Auth'
import {ContentSlot} from 'models/Content'
import styles from 'pages/Login/Login.module.scss'
import {
  AnalyticsPageNames,
  AnalyticsEventNames,
  AnalyticsExceptionNames,
} from 'models/Analytics'
import {getUtmParametersFromLocalStorage, trackUtmEvent} from 'utils/analytics'
import {isBrowserSupported} from 'utils/browser-detection'
import UnsupportedBrowserPopup from 'components/Popup/UnsupportedBrowserPopup'
import {LocalStorageKeys} from 'utils/common'
import {getConfigValue} from 'utils/environment'
import axios from 'axios'
import {ToastContext} from 'components/Toast'
import {findFirstDiffPos, getNumberOfDigits} from 'utils/forms/helpers'
import SSNLoginInput from 'components/Form/LoginSSNInput'
import {StandardError} from 'models/Errors'
import {LoginErrorTypes} from 'auth/actions/login-action'
import Banner from 'pages/Login/Banner'

const workFlowStepHasLoan = 1
const workFlowStepStateSelection = 2
const workFlowStepUsername = 3
const workFlowStepLogin = 4
const workflowStartPosition = workFlowStepHasLoan
const prequalUrl = getConfigValue('PREQUALIFY_APPLICATION_URL')
const vergentLmsUrl = getConfigValue('VERGENT_LMS_URL')
const decisionEngineServiceUrl = getConfigValue('DE_SERVICE_URL')
const enableVergentWorkflowPages = getConfigValue('EnableVergentWorkflowPages')

type LoginFormValues = AuthenticateRequest

const initialFormValues: LoginFormValues = {
  userName: '',
  password: '',
}

const FormSchema = Yup.object().shape({
  userName: Yup.string().required('A username is required'),
  password: Yup.string().required('Password is required'),
})

const Login: React.FC<{initialState?: number}> = (attributeProps?: {
  initialState?: number
}) => {
  /**
   * State, Hooks
   */
  const defaultMask = '___-__-____'
  const history = useHistory()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLookupSubmitting, setIsLookupSubmitting] = useState(false)
  const [lookupName, setLookupName] = useState('')
  const [lookupState, setLookupState] = useState('')
  const workflowState = attributeProps?.initialState
    ? Number(attributeProps.initialState)
    : workflowStartPosition
  const [workflowStep, setWorkflowStep] = useState(
    enableVergentWorkflowPages === 'true' ? workflowState : workFlowStepLogin,
  )
  const {login} = useAuth()
  const [hidePopup, setHidePopup] = useState(false)
  const [hiddenValue, setHiddenValue] = useState(defaultMask)
  const [isChanged, setIsChanged] = useState(true)
  const [trueValue, setTrueValue] = useState(defaultMask)
  const [isValidSSN, setIsValidSSN] = useState(true)
  const [ssnKeyPressed, setSsnKey] = useState(0)
  const [ssnSelection, setSsnSelection] = useState({start: 0, end: 0})

  /**
   * Context, Hooks
   */
  const {pushToast} = useContext(ToastContext)

  const ssnRegex = '^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$'
  const customRedirectUri = localStorage.getItem(
    LocalStorageKeys.CustomRedirectUri,
  )
  if (customRedirectUri) {
    localStorage.removeItem(LocalStorageKeys.CustomRedirectUri)
    window.location.replace(customRedirectUri)
  }

  const appInsightsContext = useAppInsightsContext()
  const trackMetric = useTrackMetric(
    appInsightsContext,
    AnalyticsPageNames.LOGIN,
  )

  useEffect(() => {
    trackMetric()
  }, [trackMetric])

  const utmParameters = getUtmParametersFromLocalStorage()
  // Temporary disable to be removed after a fix for 3711
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (isBrowserSupported && !hidePopup) {
    setHidePopup(true)
  }

  /**
   * Submit form values
   * @param values login form values
   */
  async function submitFormValues(values: LoginFormValues) {
    setIsSubmitting(true)
    appInsightsContext.trackEvent(
      {name: AnalyticsEventNames.LOGIN_ATTEMPT},
      {name: values.userName},
    )
    try {
      await login(values)
      appInsightsContext.trackEvent(
        {name: AnalyticsEventNames.LOGIN_ATTEMPT_SUCCESS},
        {name: values.userName},
      )
    } catch (error) {
      const {type, trackableMessage} = error as StandardError

      if (trackableMessage) {
        appInsightsContext.trackException(
          {exception: Error(AnalyticsExceptionNames.LOGIN_FAILURE)},
          {message: trackableMessage},
        )
      }

      if (type === LoginErrorTypes.AccountLockedError) {
        history.push('/locked')
      } else {
        const isInvalidCredentialsError =
          type === LoginErrorTypes.InvalidUserNameOrPassword

        pushToast({
          title: 'Unable to login',
          message: isInvalidCredentialsError
            ? 'Username and/or password is invalid'
            : 'There was an error logging in',
          variant: 'danger',
        })
      }
    } finally {
      trackUtmEvent(appInsightsContext, utmParameters)
      setIsSubmitting(false)
    }
  }

  const pastCustomerAction = useCallback(
    () => setWorkflowStep(workFlowStepStateSelection),
    [],
  )

  const newCustomerAction = useCallback(
    () => (window.location.href = prequalUrl),
    [],
  )

  const deleteHiddenDigit = useCallback(
    (valueToDeleteFrom: string, index: number) => {
      const pureValue = valueToDeleteFrom.replace(/-/g, '')
      let newTrueValue = ''
      for (let i = 0; i < pureValue.length; i++) {
        //Concatenate all characters without the one being deleted
        if (i !== index) {
          newTrueValue += pureValue[i]
        }
      }

      //Add underscore to fill the rest of the string
      newTrueValue += '_'

      //Reconstruct the value with the dashes
      return `${newTrueValue.substring(0, 3)}-${newTrueValue.substring(
        3,
        5,
      )}-${newTrueValue.substring(5, 9)}`
    },
    [],
  )

  const getNewTrueValueForAddingHidden = useCallback(
    (fieldValue: string) => {
      //Work with values without the dashes
      const pureTrueValue = trueValue.replace(/-/g, '')
      const pureFieldValue = fieldValue.replace(/-/g, '')
      const pureHiddenValue = hiddenValue.replace(/-/g, '')
      const newCharIndex = findFirstDiffPos(pureHiddenValue, pureFieldValue)
      let newTrueValue = ''
      for (let i = 0; i < pureTrueValue.length; i++) {
        //Construct the new value:
        //1. Concatenate the old value up to the new character
        //2. Add the new character
        //3. Concatenate the rest of the value shifted by 1 until we reach the same length
        if (i < newCharIndex) {
          newTrueValue += pureTrueValue[i]
        } else if (i === newCharIndex) {
          newTrueValue += pureFieldValue[newCharIndex]
        } else if (i > newCharIndex) {
          newTrueValue += pureTrueValue[i - 1]
        }
      }

      //Reconstruct the value with the dashes
      return `${newTrueValue.substring(0, 3)}-${newTrueValue.substring(
        3,
        5,
      )}-${newTrueValue.substring(5, 9)}`
    },
    [hiddenValue, trueValue],
  )

  const getNewTrueValueForDeletingHidden = useCallback(() => {
    let newTrueValue = trueValue
    const dashesInSelection =
      newTrueValue.substring(ssnSelection.start, ssnSelection.end).split('-')
        .length - 1
    const dashesBeforeSelection =
      newTrueValue.substring(0, ssnSelection.start).split('-').length - 1

    if (
      ssnSelection.start !== ssnSelection.end &&
      (ssnKeyPressed === 8 || ssnKeyPressed === 46)
    ) {
      //We have selected some of the input with length selection.end - selection.start
      //We just delete the first digit of the selection as many times as there are selected digits
      //The number of selected digits is ssnSelection.end - ssnSelection.start - dashesInSelection
      //The value should be the same if we press backspace (8) or delete (46)
      for (
        let i = 0;
        i < ssnSelection.end - ssnSelection.start - dashesInSelection;
        i++
      ) {
        newTrueValue = deleteHiddenDigit(newTrueValue, ssnSelection.start)
      }
    } else if (ssnSelection.start === ssnSelection.end) {
      //Here we have no selection, just the cursor is somewhere
      if (ssnKeyPressed === 8 && ssnSelection.start > 0) {
        //Pressing backspace (8) should only do something if cursor is not before the string
        //We delete the previous digit
        newTrueValue = deleteHiddenDigit(
          newTrueValue,
          ssnSelection.start - 1 - dashesBeforeSelection,
        )
      } else if (
        ssnKeyPressed === 46 &&
        ssnSelection.start < newTrueValue.length
      ) {
        //Pressing delete (46) should only do something if cursor is not after the string
        //Just delete the next digit
        newTrueValue = deleteHiddenDigit(
          newTrueValue,
          ssnSelection.start - dashesBeforeSelection,
        )
      }
    }
    return newTrueValue
  }, [
    deleteHiddenDigit,
    ssnKeyPressed,
    ssnSelection.end,
    ssnSelection.start,
    trueValue,
  ])

  const handleSsnSelection = useCallback(event => {
    setSsnSelection({
      start: event.target.selectionStart,
      end: event.target.selectionEnd,
    })
  }, [])

  const handleLookupSsnChange = useCallback(
    event => {
      let newTrueValue = ''
      const fieldValue = event?.target.value
      const newHiddenValue = fieldValue.replace(/[0-9]/g, '•')
      let valueToCompare
      //Determines wether the value is hidden or not
      if (fieldValue.includes('•')) {
        valueToCompare = hiddenValue
      } else {
        valueToCompare = trueValue
      }

      if (fieldValue === '') {
        //In this case everything is deleted
        newTrueValue = defaultMask
      } else if (valueToCompare === trueValue) {
        //This is the case for non-hidden: just use the actual field value
        newTrueValue = fieldValue
      } else if (getNumberOfDigits(fieldValue) === 0) {
        //This is the case where we delete from hidden
        newTrueValue = getNewTrueValueForDeletingHidden()
      } else {
        //This is the case where we are adding to hidden
        newTrueValue = getNewTrueValueForAddingHidden(fieldValue)
      }

      setTrueValue(newTrueValue)
      setHiddenValue(newHiddenValue)
      setIsChanged(!isChanged)
    },
    [
      isChanged,
      hiddenValue,
      trueValue,
      getNewTrueValueForDeletingHidden,
      getNewTrueValueForAddingHidden,
    ],
  )

  const handleSsnKeyDown = useCallback(event => {
    setSsnKey(event?.keyCode)
  }, [])

  const handleLookupNameChange = useCallback(event => {
    setLookupName(event?.target.value)
  }, [])

  const handleBlur = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
    if (trueValue.match(ssnRegex)) {
      setIsValidSSN(true)
    } else {
      setIsValidSSN(false)
    }
  }, [trueValue])

  const idahoCustomerAction = useCallback(() => {
    setLookupState('ID')
    setWorkflowStep(workFlowStepUsername)
  }, [])

  const newMexicoCustomerAction = useCallback(() => {
    setLookupState('NM')
    setWorkflowStep(workFlowStepLogin)
  }, [])

  const texasCustomerAction = useCallback(() => {
    setLookupState('TX')
    setWorkflowStep(workFlowStepUsername)
  }, [])

  const backBtnAction = useCallback(() => {
    setWorkflowStep(workflowStep - 1)
  }, [workflowStep])

  const wisconsinCustomerAction = useCallback(
    () => (window.location.href = vergentLmsUrl),
    [],
  )

  const submitLookup = useCallback(async () => {
    try {
      setIsLookupSubmitting(true)
      const result = await axios.get<boolean>(
        `${decisionEngineServiceUrl}/LoanApplication/exists?ssn=${trueValue}&state=${lookupState}`,
      )

      if (result.data) {
        setWorkflowStep(workFlowStepLogin)
      } else {
        window.location.href = vergentLmsUrl
      }
    } catch (exc) {
      pushToast({
        title: 'Customer check failed',
        message:
          'We are currently experiencing a technical issue. Please refresh your screen and resubmit the requested information. If the error persists, please call 855-279-2205 for immediate assistance.',
        variant: 'danger',
      })
      console.error(exc)
      throw exc
    }
  }, [lookupState, trueValue, pushToast])
  const backButton = (
    <div className={styles.backButton}>
      <Button variant="light" onClick={backBtnAction}>
        Back
      </Button>
    </div>
  )

  return (
    <>
      <Banner />
      {workflowStep === workFlowStepHasLoan && (
        <Container fluid className="flex-fill">
          <div className={styles.workflowOverlay}>
            <div className={styles.changesBox}>
              <div>We&apos;ve made some updates. Follow the</div>
              <div>prompts to be directed to the correct site.</div>
            </div>
            <div className={styles.question}>
              Have you ever had a loan with us?
            </div>
            <div className={styles.yesOrNoButtons}>
              <Button variant="light" onClick={pastCustomerAction}>
                YES
              </Button>
              <Button variant="light" onClick={newCustomerAction}>
                NO
              </Button>
            </div>
          </div>
        </Container>
      )}
      {workflowStep === workFlowStepStateSelection && (
        <Container fluid className="flex-fill">
          <div className={styles.workflowOverlay}>
            {backButton}
            <div className="question">
              Select the state you have had a loan in below
            </div>
            <div className={styles.stateButtons}>
              <div>
                <Button
                  className={styles.stateButton}
                  variant="light"
                  onClick={idahoCustomerAction}
                >
                  Idaho
                </Button>
              </div>
              <Button
                className={styles.stateButton}
                variant="light"
                onClick={newMexicoCustomerAction}
              >
                New Mexico
              </Button>
              <Button
                className={styles.stateButton}
                variant="light"
                onClick={texasCustomerAction}
              >
                Texas
              </Button>
              <Button
                className={styles.stateButton}
                variant="light"
                onClick={wisconsinCustomerAction}
              >
                Wisconsin
              </Button>
            </div>
          </div>
        </Container>
      )}
      {workflowStep === workFlowStepUsername && (
        <Container fluid className="flex-fill">
          <div className={styles.workflowOverlay}>
            {backButton}
            <div className="question">Provide the following details</div>
            <div className={styles.lookupInputs}>
              <BootstrapForm.Group
                className={styles.lookupInput}
                controlId="name"
              >
                <BootstrapForm.Label>Name</BootstrapForm.Label>
                <BootstrapForm.Control
                  className="form-control fs-exclude"
                  data-ref="name"
                  name="name"
                  size="lg"
                  value={lookupName}
                  onChange={handleLookupNameChange}
                />
              </BootstrapForm.Group>
              <BootstrapForm.Group
                className={styles.lookupInput}
                controlId="ssn"
              >
                <BootstrapForm.Label className={styles.inputLabel}>
                  Social Security Number
                </BootstrapForm.Label>
                <div
                  className={
                    isValidSSN
                      ? 'input-group'
                      : `input-group ${styles.invalidInput}`
                  }
                >
                  <SSNLoginInput
                    hiddenValue={hiddenValue}
                    isChanged={isChanged}
                    trueValue={trueValue}
                    onBlur={handleBlur}
                    onChange={handleLookupSsnChange}
                    onKeyDown={handleSsnKeyDown}
                    onSelect={handleSsnSelection}
                  />
                </div>
                {!isValidSSN && (
                  <BootstrapForm.Text className="text-danger">
                    Please enter a valid Social Security Number.
                  </BootstrapForm.Text>
                )}
              </BootstrapForm.Group>
            </div>
            <Button
              className={
                !isValidSSN ||
                isLookupSubmitting ||
                trueValue.trim().replace('_', '').length !== 11 ||
                !lookupName
                  ? styles.disabledInput
                  : ''
              }
              disabled={
                !isValidSSN ||
                isLookupSubmitting ||
                trueValue.trim().replace('_', '').length !== 11 ||
                !lookupName
              }
              variant="light"
              onClick={submitLookup}
            >
              Submit
            </Button>
          </div>
        </Container>
      )}
      {workflowStep === workFlowStepLogin && (
        <Container fluid className="flex-grow-1 d-flex align-items-stretch">
          {!hidePopup && (
            <UnsupportedBrowserPopup
              toggleVisiblity={setHidePopup}
              visibility={!hidePopup}
            />
          )}
          <Row className="flex-grow-1">
            <Col
              className={`${styles.gridCol} ${styles.accountCol}`}
              lg="6"
              xs="12"
            >
              <div className={`${styles.content} p-3 light`}>
                <h2 className="h1">
                  <Content type={ContentSlot.LOGIN_CREATEACCOUNTHEADER} />
                </h2>
                <div className="my-3">
                  <div className={styles.leftTextSideBar}>
                    <span>
                      Want to manage your account online? You’re only a few
                      steps away! Create your account to be eligible to:
                      <ul>
                        <br />
                        <li>Make payments.</li>
                        <li>Review your payment history and loan schedule.</li>
                        <li>Refinance your current loan.*</li>
                        <li>
                          Take out a new loan and receive funds instantly.*
                        </li>
                      </ul>
                      Click Get Started below to create your online account.
                      <br />
                      <br />
                      *Only available to eligible customers.
                    </span>
                  </div>
                  {/* <Content type={ContentSlot.LOGIN_CREATEACCOUNTSUMMARY} /> */}
                </div>
                <div className="text-center">
                  <Link
                    className="button-link"
                    data-ref="search-account"
                    to="/find-loan"
                  >
                    <Button
                      block
                      data-ref="get-started-button"
                      size="lg"
                      variant="light"
                    >
                      <span className="text-primary">
                        <Content
                          type={ContentSlot.LOGIN_GETTINGSTARTEDBUTTON}
                        />
                      </span>
                    </Button>
                  </Link>
                </div>
                <div className="my-3">
                  <div
                    className={`${styles.leftTextSideBar} text-center d-none`}
                  >
                    <span>
                      Click <a href="https://www.cashstore.com/">here</a> for
                      tutorial videos for the Customer Portal
                    </span>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              className={`${styles.gridCol} ${styles.loginCol}`}
              lg="6"
              xs="12"
            >
              <Card className={styles.loginCard}>
                <Card.Header>
                  <Content type={ContentSlot.LOGIN_TITLE} />
                  {/* {Boolean(verificationCode?.length) && (
                    <div
                      className={`alert alert-success text-center ${styles.verifyEmailAlert}`}
                    >
                      <Content type={ContentSlot.LOGIN_VERIFYEMAILALERT} />
                    </div>
                  )} */}
                </Card.Header>
                <Card.Body>
                  <Formik
                    validateOnBlur
                    initialValues={initialFormValues}
                    validateOnChange={false}
                    validationSchema={FormSchema}
                    onSubmit={submitFormValues}
                  >
                    {(props: FormikProps<LoginFormValues>) => (
                      <Form className={styles.loginForm}>
                        <div className={styles.content}>
                          <BootstrapForm.Group controlId="userName">
                            <BootstrapForm.Label color="dark">
                              Username
                            </BootstrapForm.Label>
                            <BootstrapForm.Control
                              autoComplete="userName"
                              data-ref="userName"
                              name="userName"
                              type="text"
                              value={props.values.userName}
                              onBlur={props.handleBlur}
                              onChange={props.handleChange}
                            />
                            {props.touched.userName &&
                              props.errors.userName && (
                                <BootstrapForm.Text className="text-danger">
                                  {props.errors.userName}
                                </BootstrapForm.Text>
                              )}
                          </BootstrapForm.Group>
                          <BootstrapForm.Group controlId="password">
                            <BootstrapForm.Label color="dark">
                              Password
                            </BootstrapForm.Label>
                            <BootstrapForm.Control
                              autoComplete="current-password"
                              data-ref="password"
                              name="password"
                              type="password"
                              value={props.values.password}
                              onBlur={props.handleBlur}
                              onChange={props.handleChange}
                            />
                            {props.touched.password &&
                              props.errors.password && (
                                <BootstrapForm.Text className="text-danger">
                                  {props.errors.password}
                                </BootstrapForm.Text>
                              )}
                          </BootstrapForm.Group>
                          <SpinnerButton
                            block
                            color="primary"
                            data-ref="login"
                            loading={isSubmitting}
                            size="lg"
                            type="submit"
                          >
                            <Content type={ContentSlot.LOGIN_BUTTON} />
                          </SpinnerButton>
                        </div>
                        <FormikErrorFocus
                          align="middle"
                          duration={500}
                          ease="linear"
                          focusDelay={200}
                        />
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
                <Card.Footer>
                  <Container>
                    <Row className="justify-content-center justify-content-md-start">
                      <Content type={ContentSlot.LOGIN_FORGOTPASSWORDBUTTON} />
                    </Row>
                    <Row className={`${styles.textTutorialLink} d-none`}>
                      <BootstrapForm.Label color="dark">
                        Click <a href="https://www.cashstore.com/">here</a> for
                        tutorial videos for the Customer Portal
                      </BootstrapForm.Label>
                    </Row>
                  </Container>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  )
}

/**
 * Login page
 */
export default Login
