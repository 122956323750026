import React, {useCallback, useContext, useEffect, useMemo} from 'react'

import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import {Button, Card, Col, Container, Row} from 'react-bootstrap'
import {GoChevronLeft, GoChevronRight} from 'react-icons/go'
import {Link, Redirect, useHistory} from 'react-router-dom'
import {useMutation} from 'react-query'

import {queryClient} from 'client'
import {logUnknownAsException} from 'AppInsights'
import Content from 'components/Content'
import HoursWarning from 'components/HoursWarning'
import PageSpinner from 'components/PageSpinner'
import SpinnerButton from 'components/SpinnerButton'
import TwoColumnTable from 'components/TwoColumnTable'
import {ContentSlot} from 'models/Content'
import {PostPaymentResponse} from 'models/Edge'
import {
  getPaymentData,
  setPaymentReceiptData,
  setPaymentReceiptPopUpData,
  PaymentData,
} from 'utils/cache'
import {useRefinanceData} from 'utils/hooks/useRefinanceData'
import {formatCurrency} from 'utils/data-formatting'
import {
  deleteOneTimePaymentMethods,
  getProfile,
  postPaymentRequest,
} from 'utils/edge'
import {shouldShowDuplicatePaymentWarningByStoreHours} from 'utils/dates'
import {useLoanData} from 'utils/hooks/useLoanData'
import {useStoreDetails} from 'utils/hooks/useStoreDetails'
// Import {
//   getPushToastPaymentError,
//   useOperationsPhoneNumber,
// } from 'components/Toast/PaymentError/payment-utils'
import {ToastContext} from 'components/Toast'
import FormattedDate from 'components/FormattedDate'
import {AnalyticsEventNames, AnalyticsPageNames} from 'models/Analytics'
import PaymentProcessingMessage from 'components/Form/PaymentProcessingMessage'
import {LocalStorageKeys} from 'utils/common'
import {VergentError} from '~/models/Errors'
import {useAuth} from 'auth'

dayjs.extend(utc)
dayjs.extend(timezone)

const today = new Date()

const MakePaymentConfirm: React.FC = () => {
  /**
   * Analytics & Tracking
   */
  const appInsightsContext = useAppInsightsContext()
  const trackMetric = useTrackMetric(
    appInsightsContext,
    AnalyticsPageNames.MAKE_PAYMENT,
  )
  useEffect(() => {
    trackMetric()
  }, [trackMetric])

  useEffect(() => {
    appInsightsContext.trackEvent({
      name: AnalyticsEventNames.CONFIRM_PAYMENT_VISIT,
    })
  }, [appInsightsContext])
  /**
   * State, Hooks
   */
  const {pushToast} = useContext(ToastContext)
  const history = useHistory()
  const {user} = useAuth()
  const {data, status, currentLoan, customer} = useLoanData()
  // Const operationsPhoneNumber = useOperationsPhoneNumber()
  const {data: store} = useStoreDetails(currentLoan?.originatingStore)
  const {data: refiData, status: refiStatus} = useRefinanceData()
  const addCardMethod = history.location.hash
  const paymentData = useMemo(getPaymentData, [])

  /**
   * Callbacks, mutations
   */
  const {
    mutateAsync: makePayment,
    isLoading: isPaymentSubmitting,
  } = useMutation(postPaymentRequest, {
    onSettled: processMakePaymentRequest,
  })

  const {
    mutateAsync: deleteOneTimePaymentMethodsMutation,
    isLoading: isDeletingOneTimePaymentMethods,
  } = useMutation(deleteOneTimePaymentMethods, {
    onSuccess: async () => {
      if (customer?.personReferenceNumber && user?.username) {
        await getProfile(customer.personReferenceNumber, user.username)
      }
    },
  })

  /**
   * Memoizations, callbacks needed first
   */
  const makePaymentSubmitHandler = useCallback(submitCallback, [
    data,
    paymentData,
  ])
  const confirmRows = useMemo(() => createConfirmTableData(paymentData), [
    paymentData,
  ])
  const showHoursWarning = useMemo(showHoursWarningMemo, [currentLoan, store])

  if (refiStatus === 'success') {
    let requiredPayment =
      refiData?.currentLoanDetails.nextPaymentAmount === undefined
        ? 0
        : refiData.currentLoanDetails.nextPaymentAmount
    if (currentLoan?.operatingStatus === 'PastDue') {
      requiredPayment =
        currentLoan.amountToBecomeCurrent === undefined
          ? 0
          : currentLoan.amountToBecomeCurrent
    }

    const pAmount =
      paymentData?.paymentAmount === undefined ? 0 : paymentData.paymentAmount
    const amountLeftForPayment = requiredPayment - pAmount

    if (amountLeftForPayment > 0) {
      setPaymentReceiptPopUpData({
        paymentAmount: amountLeftForPayment,
        dueDate: refiData?.currentLoanDetails.nextPaymentDate,
      })
    } else {
      setPaymentReceiptPopUpData(undefined)
    }
  }

  /**
   * Effects
   */
  useEffect(() => setPaymentReceiptData(undefined), [])

  const canSubmitPayment = status !== 'loading' && paymentData
  return (
    <Container>
      {status === 'loading' && <PageSpinner />}
      {/* The loan is valid, but they tried to load this screen directly without choosing debit card on home screen or adding one-time card */}
      {status === 'success' && !paymentData && <Redirect to="/home" />}
      {canSubmitPayment && (
        <Card className="my-2">
          <Card.Header className="text-center">
            <h1>
              <Content type={ContentSlot.CONFIRMPAYMENT_TITLE} />
            </h1>
            <p>
              <Content type={ContentSlot.CONFIRMPAYMENT_DESCRIPTION} />
            </p>
          </Card.Header>
          <Card.Body>
            <Container
              fluid
              className="card-container container-fluid border px-3 rounded"
              data-ref="confirm-table"
            >
              <TwoColumnTable
                empty="Please start again at the homepage by selecting a payment method."
                list={confirmRows}
                title="Payment Details"
              />
              {paymentData && (
                <p>
                  <strong>Please Read:</strong>
                  {` By clicking "Submit Payment" you, ${
                    paymentData.customerName
                  }, authorize Cash
                Store to debit your card ending in ${
                  paymentData.cardTokenLast4
                } for the one-time payment of
                ${formatCurrency(paymentData.paymentAmount)} on `}
                  <FormattedDate dueDate={today} />.
                </p>
              )}
            </Container>
            {showHoursWarning && <HoursWarning />}
          </Card.Body>
          <Card.Footer>
            <Container>
              <Row>{isPaymentSubmitting && <PaymentProcessingMessage />}</Row>
              <Row className="justify-content-between">
                <Col className="col-12 col-md-6 order-2 order-md-1 p-3 p-md-0 text-center text-md-left">
                  <Link to="/home">
                    <Button className="pl-0 text-dark" variant="link">
                      <GoChevronLeft />{' '}
                      <Content
                        type={ContentSlot.SUCCESSFULPAYMENT_BACKBUTTONTEXT}
                      />
                    </Button>
                  </Link>
                </Col>
                <Col className="col-12 col-md-6 p-0 order-1 order-md-2 text-right">
                  <SpinnerButton
                    color="primary"
                    data-ref="submitButton"
                    disabled={isPaymentSubmitting}
                    loading={
                      isPaymentSubmitting && isDeletingOneTimePaymentMethods
                    }
                    size="lg"
                    type="submit"
                    onClick={makePaymentSubmitHandler}
                  >
                    <Content type={ContentSlot.CONFIRMPAYMENT_BUTTONTEXT} />{' '}
                    <GoChevronRight />
                  </SpinnerButton>
                </Col>
              </Row>
            </Container>
          </Card.Footer>
        </Card>
      )}
    </Container>
  )

  /**
   * Determines if the hours warning should display
   * @return boolean of whether it should show the warning
   */
  function showHoursWarningMemo() {
    if (!currentLoan?.nextPaymentAmount || !store) return false

    const closingTime = (store[
      `${dayjs().format('dddd').toLowerCase()}Close`
    ] ?? 0) as number

    return shouldShowDuplicatePaymentWarningByStoreHours(
      currentLoan,
      new Date(),
      store.timezone.codename,
      closingTime,
    )
  }

  /**
   * Submits form data to make a payment
   */
  async function submitCallback() {
    if (!paymentData || !data) return

    const authToken = localStorage.getItem(LocalStorageKeys.AccessToken)

    appInsightsContext.trackEvent(
      {name: AnalyticsEventNames.PAYMENT_SUBMITTED},
      {
        paymentAmount: paymentData.paymentAmount,
        loanId: data.currentLoanDetails.loanId,
        storeId: data.currentLoanDetails.originatingStore,
        lastFour: paymentData.cardTokenLast4,
        personReferenceNumber: customer?.personReferenceNumber,
      },
    )

    try {
      await makePayment({
        loanId: data.currentLoanDetails.loanId,
        cardId: paymentData.cardId ?? 0,
        amountDue: paymentData.paymentAmount,
        isInRescindPeriod: data.currentLoanDetails.isRescindable,
        oAuthToken: authToken ?? '',
      })
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * Show the error in the toast, or clear the loan cache if the payment goes through
   * @param responseData payment response
   * @param error error from the API, will be used to prompt user
   */
  function processMakePaymentRequest(
    responseData?: PostPaymentResponse,
    error?: VergentError | null,
  ) {
    const fallbackMessage = 'Unable to complete payment'
    if (error) {
      pushToast({
        title: 'Payment Unsuccessful',
        message: error.errorInstance
          ? error.errorInstance.message
          : fallbackMessage,
        variant: 'danger',
      })
      appInsightsContext.trackEvent(
        {name: AnalyticsEventNames.PAYMENT_ERROR},
        {
          message: error.errorInstance
            ? error.errorInstance.message
            : fallbackMessage,
        },
      )
      logUnknownAsException(error.message)
    } else if (responseData) {
      if (!responseData.success) {
        pushToast({
          title: 'Payment Unsuccessful',
          message: fallbackMessage,
          variant: 'danger',
        })
        appInsightsContext.trackEvent(
          {name: AnalyticsEventNames.PAYMENT_ERROR},
          {
            message: fallbackMessage,
          },
        )
      }
      queryClient.removeQueries('loan')
      setPaymentReceiptData(responseData)
      if (addCardMethod === '#one-time') {
        deleteOneTimePaymentMethodsMutation(undefined)
      }

      appInsightsContext.trackEvent(
        {name: AnalyticsEventNames.PAYMENT_SUCCESS},
        {message: 'Payment Successful'},
      )

      history.push(`/payment-receipt${addCardMethod}`)
    }
    // }
  }

  /**
   * Generates the rows to be shown in the table for confirmation
   * @param pData gathered on previous screens and stored in query cache
   * @return array of ListItems
   */
  function createConfirmTableData(pData: PaymentData | undefined) {
    if (!pData) return []

    return [
      {
        label: 'Name',
        value: `${pData.customerName}`,
      },
      {
        label: 'Total Amount',
        value: formatCurrency(pData.paymentAmount),
      },
      {
        label: 'Payment Method',
        value: `${pData.cardType} ending in ${pData.cardTokenLast4}`,
      },
      {
        label: 'Date',
        value: `${dayjs(today).format('MM/DD/YYYY')}`,
      },
    ]
  }
}

/**
 * Screen that confirms payment info gathered from previous screen to make request
 */
export default MakePaymentConfirm
