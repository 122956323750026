import React from 'react'
import {Button, Container} from 'react-bootstrap'

interface AdobeSignErrorProps {
  onClick: () => void
}

const AdobeSignError: React.FC<AdobeSignErrorProps> = props => (
  <>
    <div className="text-center m-3">
      <h1>Oops</h1>
    </div>
    <Container fluid className="container-fluid p-2">
      <p>
        An error occurred while signing the documents. Please try again, and
        reach out to us if the error persists
      </p>
      <div className="text-center pt-2">
        <Button data-ref="go-home-button" onClick={props.onClick}>
          Go Home
        </Button>
      </div>
    </Container>
  </>
)

/**
 * Displays the error for AdobeSign and offers the user an opportunity to go home
 */
export default AdobeSignError
