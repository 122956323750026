import React, {useState, useCallback, useEffect, useContext} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js'
import {Form, Formik, FormikProps} from 'formik'
import FormikErrorFocus from 'formik-error-focus'
import {
  Button,
  Card,
  Col,
  Container,
  Form as BootstrapForm,
  Row,
} from 'react-bootstrap'
import ReCAPTCHA from 'react-google-recaptcha'
import {ToastContext} from 'components/Toast'
import * as Yup from 'yup'

import {appInsights, logUnknownAsException} from 'AppInsights'
import {ContentSlot} from 'models/Content'
import Content from 'components/Content'
import PasswordRules, {passwordRules} from 'components/PasswordRules'
import SpinnerButton from 'components/SpinnerButton'
import {CreateAccountFormValues} from 'models/AccountFormValues'
import styles from 'pages/CreateAccount/CreateAccount.module.scss'
import {getConfigValue} from 'utils/environment'
import {MdRemoveRedEye} from 'react-icons/md'
import {AnalyticsPageNames, AnalyticsEventNames} from 'models/Analytics'
import {getUtmParametersFromLocalStorage, trackUtmEvent} from 'utils/analytics'
import {useAuth} from 'auth'
import {StandardError} from 'models/Errors'
import {RegisterCustomerAccountErrorTypes} from 'auth/actions/register-customer-account-action'

const captchaFlag = getConfigValue('DISABLE_CAPTCHA') === 'true'

const FormSchema = Yup.object().shape({
  username: Yup.string()
    .required('A username is required')
    .test(
      'no-spaces-or-special-characters',
      'Username can only contain letters, numbers, and underscores',
      (username: string) => {
        // Below regex checks for spaces, special characters
        const regex = /^(\d|\w|@|\.)+$/
        // Regex.exec is much faster than string.match https://github.com/typescript-eslint/typescript-eslint/blob/v2.34.0/packages/eslint-plugin/docs/rules/prefer-regexp-exec.md
        if (!regex.exec(username)) {
          return false
        }
        return true
      },
    )
    .test(
      'username-length',
      'Username must be between 4 and 32 characters',
      (username = '') => username.length > 3 && username.length < 33,
    ),
  email: Yup.string()
    .email('A valid email address is required')
    .required('Email address is required'),
  ...passwordRules,
  terms: Yup.bool().oneOf([true], 'Terms must be checked'),
  recaptcha: captchaFlag
    ? Yup.string().nullable()
    : Yup.string().required('Recaptcha is required').nullable(),
})

const CreateAccount: React.FC = () => {
  /**
   * Analytics & Tracking
   */
  const appInsightsContext = useAppInsightsContext()
  const trackMetric = useTrackMetric(
    appInsightsContext,
    AnalyticsPageNames.CREATE_ACCOUNT,
  )
  useEffect(() => {
    trackMetric()
  }, [trackMetric])

  /**
   * State, Hooks
   */
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hidden, setHidden] = useState(true)
  const {registerCustomerAccount, user, resetRegisterFlow} = useAuth()
  const {pushToast} = useContext(ToastContext)
  const history = useHistory()

  useEffect(() => {
    if (!user?.personReferenceNumber) {
      history.push('/find-loan')
    }
    return () => resetRegisterFlow()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, resetRegisterFlow])

  /**
   * Callbacks
   */
  const togglePassword = useCallback(() => {
    setHidden(!hidden)
  }, [hidden, setHidden])

  const initialFormValues: CreateAccountFormValues = {
    username: '',
    password: '',
    email: '',
    confirmPassword: '',
    terms: false,
    recaptcha: '',
  }

  const utmParameters = getUtmParametersFromLocalStorage()

  /**
   * Submit form values
   * @param values form values
   */
  async function submitFormValues(values: CreateAccountFormValues) {
    setIsSubmitting(true)

    const formValuesWithoutPasswords: CreateAccountFormValues = {
      ...values,
      password: '**********',
      confirmPassword: '**********',
    }

    try {
      await registerCustomerAccount({
        userName: values.username,
        password: values.password,
        email: values.email,
        customerId: Number(user?.personReferenceNumber),
      })

      appInsightsContext.trackEvent(
        {name: AnalyticsEventNames.CREATE_ACCOUNT},
        formValuesWithoutPasswords,
      )
      appInsights.setAuthenticatedUserContext(
        formValuesWithoutPasswords.username,
        formValuesWithoutPasswords.username,
      )

      trackUtmEvent(appInsightsContext, utmParameters)
    } catch (error) {
      const {type, errorInstance} = error as StandardError
      const errorTitle = 'Unable to create account'

      if (errorInstance) logUnknownAsException(errorInstance)

      if (type === RegisterCustomerAccountErrorTypes.NoValidUserName) {
        pushToast({
          message: 'Username is not unique',
          title: errorTitle,
          variant: 'danger',
        })
      } else if (type === RegisterCustomerAccountErrorTypes.ExistingUser) {
        pushToast({
          message: 'Account Already has a user',
          title: errorTitle,
          variant: 'danger',
        })
      } else if (
        type === RegisterCustomerAccountErrorTypes.InternalServerError
      ) {
        pushToast({
          message: 'There was an error registering',
          title: errorTitle,
          variant: 'danger',
        })
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Container>
      <Card className="full-page mt-3">
        <Card.Header className="text-center">
          <h1>
            <Content type={ContentSlot.CREATEACCOUNT_HEADER} />
          </h1>
          <div>
            <Content type={ContentSlot.CREATEACCOUNT_SUMMARY} />
          </div>
        </Card.Header>

        <Formik
          validateOnBlur
          initialValues={initialFormValues}
          validateOnChange={false}
          validationSchema={FormSchema}
          onSubmit={submitFormValues}
        >
          {(props: FormikProps<CreateAccountFormValues>) => (
            <Form noValidate>
              <Card.Body>
                <Row>
                  <Col lg="6" xs="12">
                    <BootstrapForm.Group controlId="username">
                      <BootstrapForm.Label>
                        Desired Username
                      </BootstrapForm.Label>
                      <BootstrapForm.Control
                        autoComplete="username"
                        data-ref="desired-username"
                        name="username"
                        type="text"
                        value={props.values.username}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                      />
                      {props.touched.username && props.errors.username && (
                        <BootstrapForm.Text
                          className="text-danger"
                          data-ref="username-validation-error"
                        >
                          {props.errors.username}
                        </BootstrapForm.Text>
                      )}
                    </BootstrapForm.Group>
                  </Col>
                  <Col lg="6" xs="12">
                    <BootstrapForm.Group controlId="email">
                      <BootstrapForm.Label>Email Address</BootstrapForm.Label>
                      <BootstrapForm.Control
                        data-ref="email-address"
                        name="email"
                        type="email"
                        value={props.values.email}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                      />
                      {props.touched.email && props.errors.email && (
                        <BootstrapForm.Text
                          className="text-danger"
                          data-ref="email-validation-error"
                        >
                          {props.errors.email}
                        </BootstrapForm.Text>
                      )}
                    </BootstrapForm.Group>
                  </Col>
                </Row>
                <Row className="justify-content-center pb-3">
                  <Col lg="6" xs="12">
                    <BootstrapForm.Group controlId="password">
                      <BootstrapForm.Label>Password</BootstrapForm.Label>
                      <div className="input-group">
                        <BootstrapForm.Control
                          autoComplete="new-password"
                          data-ref="password"
                          name="password"
                          type={hidden ? 'password' : 'text'}
                          value={props.values.password}
                          onBlur={props.handleBlur}
                          onChange={props.handleChange}
                        />
                        <div className="input-group-append">
                          <Button
                            className="btn-medium"
                            onClick={togglePassword}
                          >
                            <MdRemoveRedEye className={`${styles.icon}`} />
                          </Button>
                        </div>
                      </div>
                      {props.touched.password && props.errors.password && (
                        <BootstrapForm.Text
                          className="text-danger"
                          data-ref="password-validation-error"
                        >
                          {props.errors.password}
                        </BootstrapForm.Text>
                      )}
                    </BootstrapForm.Group>
                  </Col>
                  <Col className="order-2 order-lg-1" lg="6" xs="12">
                    <BootstrapForm.Group controlId="confirmPassword">
                      <BootstrapForm.Label>
                        Retype Password
                      </BootstrapForm.Label>
                      <BootstrapForm.Control
                        autoComplete="new-password"
                        data-ref="confirm-password"
                        name="confirmPassword"
                        type={hidden ? 'password' : 'text'}
                        value={props.values.confirmPassword}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                      />
                      {props.touched.confirmPassword &&
                        props.errors.confirmPassword && (
                          <BootstrapForm.Text
                            className="text-danger"
                            data-ref="confirm-password-validation-error"
                          >
                            {props.errors.confirmPassword}
                          </BootstrapForm.Text>
                        )}
                    </BootstrapForm.Group>
                  </Col>
                  <Col className="order-1 order-lg-2 pb-2" lg="12">
                    <PasswordRules
                      error={Boolean(
                        props.touched.password && props.errors.password,
                      )}
                      password={props.values.password}
                    />
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col lg="6" xs="12">
                    <BootstrapForm.Group controlId="terms">
                      <Container>
                        <BootstrapForm.Check
                          className={`${styles.checkBoxContainer}`}
                          data-ref="terms"
                          label={
                            <Content type={ContentSlot.CREATEACCOUNT_TERMS} />
                          }
                          name="terms"
                          type="checkbox"
                          value="Accepted"
                          onChange={props.handleChange}
                        />
                      </Container>
                      {props.touched.terms && props.errors.terms && (
                        <BootstrapForm.Text
                          className="text-danger"
                          data-ref="terms-validation-error"
                        >
                          {props.errors.terms}
                        </BootstrapForm.Text>
                      )}
                    </BootstrapForm.Group>
                  </Col>
                  <Col
                    className={`${styles.recaptchaWrapper} text-center`}
                    lg="6"
                    xs="12"
                  >
                    <BootstrapForm.Group>
                      <ReCAPTCHA
                        className={captchaFlag ? `${styles.hidden}` : ''}
                        sitekey={getConfigValue('GOOGLE_RECAPTCHA_KEY')}
                        // eslint-disable-next-line react/jsx-no-bind
                        onChange={(h: string) => {
                          props.setFieldValue('recaptcha', h)
                        }}
                      />
                      {props.touched.recaptcha && props.errors.recaptcha && (
                        <BootstrapForm.Text
                          className="text-danger"
                          data-ref="recaptcha-validation-error"
                        >
                          {props.errors.recaptcha}
                        </BootstrapForm.Text>
                      )}
                    </BootstrapForm.Group>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col lg="6" xs="12">
                    <SpinnerButton
                      block
                      className={`${styles.submitButton}`}
                      color="primary"
                      data-ref="submit"
                      loading={isSubmitting}
                      size="lg"
                      type="submit"
                    >
                      <Content type={ContentSlot.CREATEACCOUNT_SUBMITBUTTON} />
                    </SpinnerButton>
                  </Col>
                </Row>
              </Card.Body>
              <FormikErrorFocus
                align="middle"
                duration={500}
                ease="linear"
                focusDelay={200}
              />
            </Form>
          )}
        </Formik>

        <Card.Footer className="text-center">
          <Link to="/login">
            <button
              className={`alert
              alert-success
              text-center
              mt-1
              d-inline-block
              ${styles.createAccountText}`}
              type="button"
            >
              Already have an account? <b>Log In</b>
            </button>
          </Link>
        </Card.Footer>
      </Card>
    </Container>
  )
}

/**
 * Create Account page
 */
export default CreateAccount
