import React, {useCallback, useContext, useEffect, useState} from 'react'

import {ToastContext} from 'components/Toast'
import {Col, Row} from 'react-bootstrap'

import {formatPhone} from 'utils/data-formatting'
import {Props as ContactInfoProps} from 'pages/Account/ContactInfo'
import styles from 'components/ContactInfo/ContactInfo.module.scss'
import SpinnerButton from '../SpinnerButton'
import {getRequestPinByText} from 'utils/edge'
import {RequestPinByTextBody} from 'models/Edge'
import {AiOutlineCheckCircle} from 'react-icons/ai'
import VerifyNumberPopup from './VerifyNumberPopup'

interface ContactReadOnlyProps extends ContactInfoProps {
  showVerifyButton: boolean
  disableNextButtton(disabed: boolean): void | undefined
}

const ContactReadOnly: React.FC<ContactReadOnlyProps> = ({
  userData,
  showVerifyButton,
  disableNextButtton,
}: ContactReadOnlyProps) => {
  const {pushToast} = useContext(ToastContext)
  const [isLoading, setIsLoading] = useState(false)
  const [showButton, setShowButton] = useState(true)
  const [showVerifyNumberPopUp, setShowVerifyNumberPopup] = useState(false)

  const toggleVerifyNumberPopUp = useCallback(
    toggleVerifyNumberPopUpCallback,
    [showVerifyNumberPopUp, setShowVerifyNumberPopup],
  )
  const hideBeginVerificationButton = () => setShowButton(false)
  const setVerificationButtonIsLoanding = (loading: boolean) =>
    setIsLoading(loading)

  /**
   * Simple callback to toggle local state
   */
  function toggleVerifyNumberPopUpCallback() {
    setShowVerifyNumberPopup(!showVerifyNumberPopUp)
  }

  /**
   * Data
   */
  const address = userData?.customerAddress
  const phone = userData?.customerPhoneNumber

  useEffect(() => {
    disableNextButtton(showButton)
  }, [disableNextButtton, showButton])

  const handlePinRequestSubmit = async () => {
    const values: RequestPinByTextBody = {
      phoneNumber: phone?.phoneNumber ?? '',
      type: 2,
    }
    setIsLoading(true)
    const pinByTextData = await getRequestPinByText(values)
    if (pinByTextData) {
      if (pinByTextData.message === 'SKIP') {
        hideBeginVerificationButton()
        disableNextButtton(false)
        return
      }
      if (pinByTextData.result) {
        toggleVerifyNumberPopUpCallback()
        return
      }
      setIsLoading(false)
      pushToast({
        title: 'Unable to send',
        message: `We wern't able to send the pin code to the provided phone number. Please try again`,
        variant: 'danger',
      })
    }
  }

  const returnVerifyButton = (
    verifyVisibility: boolean,
    buttonVisibility: boolean,
  ) => {
    if (verifyVisibility) {
      return <></>
    }

    if (buttonVisibility) {
      return (
        <>
          <SpinnerButton
            color="primary"
            data-ref="submit"
            loading={isLoading}
            size="sm"
            onClick={handlePinRequestSubmit}
          >
            Begin Cell Phone Verification
          </SpinnerButton>
          <div className={`${styles.verificationTextContainer}`}>
            *Cell phone verification is required to proceed. A verification
            code will be sent to the cell phone number provided.
          </div>
        </>
      )
    }

    return (
      <b className={styles.VerificationText}>
        Cell phone number is verified
        <AiOutlineCheckCircle color="green" />
      </b>
    )
  }

  return (
    <>
      <VerifyNumberPopup
        disableNextButton={disableNextButtton}
        hideBeginVerificationButton={hideBeginVerificationButton}
        phoneNumber={phone?.phoneNumber}
        setVerificationButtonIsLoanding={setVerificationButtonIsLoanding}
        toggleVisiblity={toggleVerifyNumberPopUp}
        visility={showVerifyNumberPopUp}
      />
      {Boolean(userData) && (
        <>
          <Row className={`${styles.Row}`}>
            <Col>
              <p className="mb-1">Street Address</p>
              <p>
                <b>{address?.addressLine1}</b>
              </p>
              {Boolean(address?.addressLine2) && (
                <p>
                  <b>{address?.addressLine2}</b>
                </p>
              )}
            </Col>
          </Row>
          <Row className={`${styles.Row}`}>
            <Col md="4" xs="12">
              <p className="mb-1">City</p>
              <p>
                <b>{address?.city}</b>
              </p>
            </Col>
            <Col md="4" xs="12">
              <p className="mb-1">State</p>
              <p>
                <b>{address?.state}</b>
              </p>
            </Col>
            <Col md="4" xs="6">
              <p className="mb-1">Zip</p>
              <p>
                <b>{address?.postalCode}</b>
              </p>
            </Col>
          </Row>
          <Row className={`${styles.Row}`}>
            <Col>
              <div className={styles.PhoneContainer}>
                <div>
                  <p className="mb-1">Cell Phone Number</p>
                  <b>{phone && formatPhone(phone.phoneNumber)}</b>
                </div>
                {returnVerifyButton(!showVerifyButton, showButton)}
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

/**
 * Contact Read Only Card Content
 */
export default ContactReadOnly
