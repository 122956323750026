/**
 * Object used to store the hard-coded routes to be used in several components
 */
export const Routes = {
  ACCOUNT: '/account',
  ACCOUNT_ISSUE: '/account-issue',
  ACCOUNT_LOCKED: '/locked',
  ADD_CARD: '/add-card',
  ADD_CARD_AND_PAY: '/add-card-and-pay',
  BASE: '/',
  BUILDINFO: '/buildinfo',
  CASHBACK: '/cash-back',
  CASHBACK_THANKS: '/cashback-thank-you',
  CASHWISE_WELCOME_BACK: '/cashwise-customer',
  CASHWISE_LANDING: '/cashwise',
  CHOOSE_REFINANCE: '/choose-refinance',
  CONFIRM_INFO: '/confirm-information',
  CONFIRM_ORIGINATION: '/confirm-origination',
  CONFIRM_PAYMENT: '/payment-confirm',
  CONTACT_US: '/contact-us',
  EMPLOYER: '/employer-info-changed',
  FIND_LOAN: '/find-loan',
  FORGOT_PASS: '/forgot-password',
  HOME: '/home',
  LOGIN: '/login',
  NO_LOANS: '/no-loan-found',
  NOT_FOUND: '/404',
  PAYMENT_RECEIPT: '/payment-receipt',
  REACTIVATE: '/reactivate',
  REACTIVATE_ISSUE: '/reactivate-issue',
  REACTIVATION_THANKS: '/reactivation-thank-you',
  REFINANCE_ISSUE: '/refinance-issue',
  REFINANCE: '/principal-refinance',
  REFINANCE_THANKS: '/refinance-thank-you',
  REGISTER: '/register',
  RESET_PASS: '/reset-password',
  SERVICE_OUTAGE: '/outage',
  SIGN_DOCUMENTS: '/sign-documents',
  FINISH_SIGNATURE: '/finish-signature',
}

/**
 * Array of Routes that can be visited without being authenticated.
 */
export const anonymousRoutes = [
  Routes.LOGIN,
  Routes.REGISTER,
  Routes.FORGOT_PASS,
  Routes.NOT_FOUND,
  Routes.BASE,
  Routes.CASHWISE_WELCOME_BACK,
  Routes.CASHWISE_LANDING,
  Routes.SERVICE_OUTAGE,
  Routes.BUILDINFO,
]

/**
 * Array of Routes that can only be visited while authenticated
 */
export const authenticatedRoutes = [
  Routes.ACCOUNT,
  Routes.ACCOUNT_ISSUE,
  Routes.ACCOUNT_LOCKED,
  Routes.ADD_CARD,
  Routes.CASHBACK,
  Routes.CASHBACK_THANKS,
  Routes.CHOOSE_REFINANCE,
  Routes.CONFIRM_INFO,
  Routes.CONFIRM_ORIGINATION,
  Routes.CONFIRM_PAYMENT,
  Routes.CONTACT_US,
  Routes.EMPLOYER,
  Routes.FIND_LOAN,
  Routes.HOME,
  Routes.NO_LOANS,
  Routes.NOT_FOUND,
  Routes.PAYMENT_RECEIPT,
  Routes.REACTIVATE,
  Routes.REACTIVATE_ISSUE,
  Routes.REACTIVATION_THANKS,
  Routes.REFINANCE,
  Routes.REFINANCE_ISSUE,
  Routes.REFINANCE_THANKS,
  Routes.RESET_PASS,
  Routes.SERVICE_OUTAGE,
  Routes.SIGN_DOCUMENTS,
]
