import {
  ProfileResponse,
  CustomerDetailsResponse,
  FullProcessInfoModel,
  RefinanceType,
  Transaction,
  TransactionModel,
  ScheduleItem,
  PaymentSchedule,
  GetRefinanceEstimateResponse,
  ReactivationApprovalDetails,
  RefinanceApprovalDetail,
  CustomerDetails,
} from 'models/Edge'
import {PaymentFrequency, PaymentFrequencyType} from 'models/PaymentFrequency'
import {convertDateToCurrentTimezone} from 'utils/convert-date-to-current-timezone'
import {getConfigValue} from './environment'

enum DisabledReactivationRefiStatuses {
  ActiveMilitary = 'Active Military',
  Login = 'Bankrupt',
  DebtConsolidation = 'Debt Consolidation',
  Deceased = 'Deceased',
  DoneBorrowing = 'Done Borrowing',
  HOCollections = 'H.O. Collections',
  Legal = 'Legal',
  Sold = 'Sold',
  OnHold = 'On Hold',
}

const EMPTY_ADDRESS = {
  addressId: 0,
  addressLine1: '',
  addressLine2: '',
  city: '',
  moveInDate: null,
  postalCode: '',
  state: '',
}

/**
 * Utility to build customer details with the data returned by vergent
 * @param user [ProfileResponse] user information from profile endpoint
 * @return CustomerDetails
 */
export const buildCustomerDetails = (
  user: ProfileResponse | null,
): CustomerDetails => {
  try {
    const userBanks = user?.banks?.[0] ?? undefined

    const userPhones =
      user?.phones.find(phone => phone.isPrimary) ??
      user?.phones?.[0] ??
      undefined

    const userAddresses =
      user?.addresses?.find(address => address.isPrimary) ?? undefined

    const userEmployers =
      user?.employers?.find(employer => employer.isPrimaryEmp) ?? undefined

    return {
      customerMobileProfileId: user?.customerMobileProfileId ?? 0,
      customerAddress: userAddresses
        ? {
            addressId: userAddresses.id,
            addressLine1: userAddresses?.addressLine1 ?? '',
            addressLine2: userAddresses?.addressLine2 ?? '',
            city: userAddresses?.city ?? '',
            moveInDate: userAddresses?.moveInDate ?? null,
            postalCode: userAddresses?.zip ?? '',
            state: userAddresses?.stateAbbreviation ?? '',
          }
        : EMPTY_ADDRESS,
      customerCards:
        user?.cards?.reverse()?.filter(card => !card.isOneTimeDebitCard) ?? [],
      customerBankAccounts: [
        {
          id: userBanks?.id ?? 0,
          accountNumber: userBanks?.accountNumberMasked ?? '',
          bankName: userBanks?.name ?? '',
          isBankAccountActive: Boolean(userBanks?.isBankAccountActive),
          isCheckingAccount: Boolean(userBanks?.isCheckingAccount),
        },
      ],
      customerEmployers: [
        {
          id: userEmployers?.id ?? 0,
          employerName: userEmployers?.name ?? '',
          frequencyOfPay: userEmployers?.payFrequency ?? '',
          grossPay: userEmployers?.payAmount.toString() ?? '',
          paycheckAmount: userEmployers?.netPayAmount ?? 0,
        },
      ],
      customerPhoneNumber: {
        phoneId: userPhones?.id ?? 0,
        phoneNumber: userPhones?.number ?? '',
        phoneNumberType: userPhones?.type ?? '',
      },
      personReferenceNumber: String(user?.customerId),
      personId: String(user?.customerId),
      companyId: user?.companyId ?? 0,
      portalDisabled: false,
      reactivationRefiDisabled: Object.values<string>(
        DisabledReactivationRefiStatuses,
      ).includes(user?.customerStatus ?? ''),
      emailAddress: user?.email ?? '',
      birthDate: user?.birthDate ?? '',
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      customerStatus: user?.customerStatus ?? '',
      reactivationOnlineStoreId: user?.reactivationOnlineStoreId,
      refinanceModelOption: user?.refinanceLoanModelOptions ?? [],
    }
  } catch (error) {
    console.error(error)
    return {
      customerMobileProfileId: 0,
      customerAddress: EMPTY_ADDRESS,
      customerCards: [],
      customerBankAccounts: [],
      customerPhoneNumber: {
        phoneId: 0,
        phoneNumber: '',
        phoneNumberType: '',
      },
      customerEmployers: [],
      personReferenceNumber: '',
      personId: '',
      companyId: 0,
      portalDisabled: false,
      reactivationRefiDisabled: false,
      emailAddress: '',
      birthDate: '',
      firstName: '',
      lastName: '',
      customerStatus: '',
      reactivationOnlineStoreId: user?.reactivationOnlineStoreId,
      refinanceModelOption: [],
    }
  }
}

interface BuildLoanDetailsArgs {
  user: ProfileResponse | null
  customerLoanData: FullProcessInfoModel | undefined | null
  loanTransactionHistoryList: TransactionModel[] | undefined
  loanScheduleHistoryList: PaymentSchedule[]
  regularRefinanceEstimate: GetRefinanceEstimateResponse | null
  cashbackRefinanceEstimate: GetRefinanceEstimateResponse | null
  maximumApprovalAmount: number
}

/**
 * Utility to build loan details with the data returned by vergent
 * @return Customer details
 */
export const buildLoanDetails = ({
  user,
  customerLoanData,
  loanTransactionHistoryList,
  loanScheduleHistoryList,
  regularRefinanceEstimate,
  cashbackRefinanceEstimate,
  maximumApprovalAmount,
}: BuildLoanDetailsArgs): CustomerDetailsResponse => {
  const customerDetails = buildCustomerDetails(user)

  const paymentTransactions: Transaction[] = loanTransactionHistoryList
    ? loanTransactionHistoryList.reduce(
        (newTransaction: Transaction[], transaction) => {
          const PaymentTypeRegex = /^(payment|Payment)/

          if (PaymentTypeRegex.test(transaction.typeName)) {
            newTransaction.push({
              transactionid: transaction.transactionItemId,
              amount: transaction.total,
              authorizationType: 'DebitCard',
              effectiveDate: transaction.businessDate,
              paymentType: transaction.typeName,
              postingDate: transaction.businessDate,
              transactionType: 'transaction',
            })
          }

          return newTransaction
        },
        [],
      )
    : []

  const loanSchedule: ScheduleItem[] = loanScheduleHistoryList.map(
    schedule => ({
      dueAmount: schedule.payment,
      dueDate: schedule.dueDate,
      graceDueDate: schedule.dueDate,
    }),
  )

  let reactivationApprovalDetails: ReactivationApprovalDetails[] | null = null

  if (
    !customerLoanData?.isEligibleForRefinance &&
    !user?.isEligibleForRefinance &&
    customerLoanData?.payoffAmount === 0 &&
    maximumApprovalAmount > 0 &&
    user?.isEligibleForNewLoan &&
    !customerDetails.reactivationRefiDisabled
  ) {
    reactivationApprovalDetails = customerLoanData.isTitleLoan
      ? null
      : [
          {
            numberOfPeriods:
              cashbackRefinanceEstimate?.paymentSchedule.length ?? 0,
            approvedAmount: maximumApprovalAmount,
            periodLengthField:
              cashbackRefinanceEstimate?.paymentFrequency ?? '',
            productId: 0,
            suggestedFirstPaymentDates: [''],
          },
        ]
  }

  let refinanceApprovalDetails: RefinanceApprovalDetail | null = null

  const storeStateMatch =
    user?.addresses[0].stateAbbreviation === customerLoanData?.storeState

  const isSigningEnable = getConfigValue('ENABLE_ESIGN_DOCUMENT')
  if (
    customerLoanData?.isEligibleForRefinance &&
    regularRefinanceEstimate &&
    user?.isEligibleForRefinance &&
    !customerDetails.reactivationRefiDisabled &&
    storeStateMatch
  ) {
    const suggestedRegularFirstPaymentDate = (convertDateToCurrentTimezone(
      (regularRefinanceEstimate.paymentSchedule[0]
        .dueDate as unknown) as string,
    ) as Date).toISOString()

    const cashbackAmount =
      maximumApprovalAmount - customerLoanData.currentBalance
    const isCashbackEligible = Boolean(
      Boolean(customerLoanData.isEligibleForRefinance) && cashbackAmount > 0,
    )
    refinanceApprovalDetails = {
      numberOfPeriods: regularRefinanceEstimate.paymentSchedule.length,
      periodLengthField: regularRefinanceEstimate.paymentFrequency,
      paymentFrequencyId:
        PaymentFrequency[
          regularRefinanceEstimate.paymentFrequency as PaymentFrequencyType
        ],
      refinanceOptions: [
        {
          cashbackAmount: 0,
          refinanceType: RefinanceType.REFINANCE,
          requiredToPay:
            regularRefinanceEstimate.paymentAmountRequiredToRefinance,
        },
        ...(isCashbackEligible
          ? [
              {
                cashbackAmount:
                  maximumApprovalAmount -
                  (customerLoanData.currentBalance -
                    regularRefinanceEstimate.paymentAmountRequiredToRefinance),
                refinanceType: RefinanceType.CASHBACK,
                requiredToPay:
                  regularRefinanceEstimate.paymentAmountRequiredToRefinance,
              },
            ]
          : []),
      ],
      suggestedFirstPaymentDates: [suggestedRegularFirstPaymentDate],
      hasRefinanceOption:
        Boolean(customerLoanData.isEligibleForRefinance) &&
        !customerLoanData.isTitleLoan,
      hasCashbackOption: isCashbackEligible && !customerLoanData.isTitleLoan,
      refiStoreId: user.refinanceLoanModelOptions[0].storeId,
      refiLoanModelId: user.refinanceLoanModelOptions[0].loanModelId,
    }
  }

  let paymentFrequencyId = 0
  if (customerLoanData?.loanModelName.toLowerCase().includes('monthly', 0))
    paymentFrequencyId = PaymentFrequency.Monthly
  if (customerLoanData?.loanModelName.toLowerCase().includes('biweekly', 0)) {
    paymentFrequencyId = PaymentFrequency['Bi-Weekly']
  }
  if (refinanceApprovalDetails) {
    refinanceApprovalDetails.paymentFrequencyId = paymentFrequencyId
  }

  const isActiveLoanStatus =
    customerLoanData?.loanStatus === 'Open' ||
    customerLoanData?.loanStatus === 'Default' ||
    customerLoanData?.loanStatus === 'Pending'

  const delinquentAmountToBecomeCurrent =
    customerLoanData?.storeState === 'WI' ||
    customerLoanData?.storeState === 'ID'
      ? customerLoanData?.owedToBeCurrentAmount
      : customerLoanData?.pastDueAmount

  return {
    currentLoanDetails: {
      amountToBecomeCurrent:
        customerLoanData?.actualLoanStatus === 'Delinquent'
          ? delinquentAmountToBecomeCurrent
          : customerLoanData?.amountDue,
      isAutoPaymentEnabled: Boolean(customerLoanData?.isAutoPayEnabled),
      isEligibleForRefinance: Boolean(
        customerLoanData?.isEligibleForRefinance,
      ),
      isSigningComplete:
        isSigningEnable === 'true' && isActiveLoanStatus
          ? Boolean(customerLoanData?.isSigningComplete)
          : true,
      isSigningPending:
        isSigningEnable === 'true' && isActiveLoanStatus
          ? Boolean(customerLoanData?.isSigningPending)
          : false,
      isRefinance: customerLoanData?.isRefinance,
      isRescindable: Boolean(customerLoanData?.isInRescindPeriod),
      loanHeaderId: customerLoanData?.loanHeaderId,
      loanId: customerLoanData?.loanHeaderId ?? 0,
      loanSchedule,
      nextPaymentAmount: customerLoanData?.nextPaymentScheduleAmount ?? 0,
      nextPaymentDate: customerLoanData?.nextPaymentDate ?? new Date(),
      nextPaymentScheduledDate:
        customerLoanData?.nextPaymentScheduleDate ?? new Date(),
      originationDate: customerLoanData?.originationDate,
      originatingStore: customerLoanData?.storeNumber ?? '',
      isOnlineStore:
        customerLoanData?.storeNumber === '7799' ||
        customerLoanData?.storeNumber === '299' ||
        customerLoanData?.storeNumber === '899',
      isServicingStoreOnlineStore:
        customerLoanData?.servicingStoreNumber === '7799' ||
        customerLoanData?.servicingStoreNumber === '299' ||
        customerLoanData?.servicingStoreNumber === '899',
      currentBalance: customerLoanData?.currentBalance ?? 0,
      feeBalance: customerLoanData?.feeBalance ?? 0,
      storeId: String(customerLoanData?.storeId),
      servicingStoreNumber: customerLoanData?.servicingStoreNumber,
      storeState: String(customerLoanData?.storeState),
      publicLoanId: customerLoanData?.publicLoanId,
      paymentTransactions,
      todaysPayoffAmount: customerLoanData?.payoffAmount ?? 0,
      refinanceApprovalDetails,
      reactivationApprovalDetails,
      operatingStatus: customerLoanData?.loanStatus ?? '',
      originalFeeApr: customerLoanData?.originalFeeApr ?? 0,
      actualLoanStatus: customerLoanData?.actualLoanStatus ?? '',
      activeLoanFlags: [''],
      loanModelId: customerLoanData?.loanModelId ?? 0,
    },
    customerDetails,
    vergentStoreGoLiveDate: null, // TODO: declare env
  }
}
