import {Routes} from 'models/Routes'

/* eslint-disable no-console */
declare global {
  interface Window {
    appConfig?: {[key: string]: string}
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let appSettings: any

/**
 * Loads Configuration
 */
export const loadConfiguration = () => {
  try {
    console.log(`LOADING APPSETTINGS...`)
    const request = new XMLHttpRequest()
    request.open('GET', '/assets/appSettings.php', false)
    request.send()

    if (request.responseText.startsWith('<')) {
      //  This is the actual PHP file and not the contents.
      console.log('NOT USING AZURE APPSETTINGS')
      return
    }

    appSettings = JSON.parse(request.responseText)
    console.log(`LOADED APPSETTINGS`)

    console.log(Routes.FINISH_SIGNATURE)

    if (
      appSettings['CONSOLE_LOG_KEYS'] !== undefined &&
      appSettings['CONSOLE_LOG_KEYS'] === 'true'
    ) {
      for (const key in appSettings) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        console.log(`From Azure - Key: '${key}' = '${appSettings[key]}'`)
      }
    }
  } catch (exc) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    console.error(`Couldn't load appSettings! ${exc}`)
  }
}

loadConfiguration()

/**
 * Returns a the config variable set in the release variable group, or the corresponding env variable if it is set
 * @param key variable name
 * @return string variable value
 */
// @ts-ignore
export const getConfigValue = (key: string): string => {
  if (appSettings?.[key]) {
    return appSettings[key]
  }
  return (
    process.env[`REACT_APP_${key}`] ??
    (window.appConfig ? window.appConfig[key] : '')
  )
}
