/**
 * Enum of possible AnalyticsPageNames
 * This list shows all names of pages we track in CustomerPortal
 */
export enum AnalyticsPageNames {
  ACCOUNT = 'Account',
  ACCOUNT_ISSUE = 'AccountIssue',
  ACCOUNT_LOCKED = 'AccountLocked',
  ADD_DEBIT_CARD = 'AddDebitCard',
  CASHBACK = 'Cashback',
  CONFIRM_INFO = 'ConfirmInfo',
  CONFIRM_ORIGINATION = 'ConfirmOrigination',
  CONTACT_US = 'ContactUs',
  CREATE_ACCOUNT = 'CreateAccount',
  EMPLOYER_INFO_CHANGED = 'EmployerInfoChanged',
  FIND_LOAN = 'FindLoan',
  FORGOT_PASSWORD = 'ForgotPassword',
  HOME = 'Home',
  LOGIN = 'Login',
  MAKE_PAYMENT = 'MakePayment',
  MAKE_PAYMENT_RECEIPT = 'MakePaymentReceipt',
  NO_LOANS = 'NoLoans',
  REACTIVATE = 'Reactivate',
  REACTIVATE_ERROR = 'ReactivateError',
  REFINANCE = 'Refinance',
  REFINANCE_ERROR = 'RefinanceError',
  REFINANCE_OPTIONS = 'RefinanceOptions',
  RESET_PASSWORD = 'ResetPassword',
  SIGN_DOCUMENTS = 'SignDocuments',
  UH_OH = 'UhOh',
  VERIFY_EMAIL = 'VerifyEmail',
  CASHWISE_WELCOME_BACK = 'CashwiseWelcomeBack',
  CASHWISE_LANDING = 'CashwiseLandingPage',
}

/**
 * Enum of possible AnalyticsEventNames
 * This list shows all names of events we track in CustomerPortal
 */
export enum AnalyticsEventNames {
  ACCOUNT_ISSUE_REDIRECT = 'CustomerPortal:CustomerIneligibilityRedirect',
  ADD_DEBIT_CARD = 'CustomerPortal:AddDebitCard-Success',
  CREATE_ACCOUNT = 'CustomerPortal:CreateAccount',
  FIND_LOAN = 'CustomerPortal:FindLoan',
  FIND_LOAN_FAILURE = 'CustomerPortal:FindLoanFailure',
  FIND_LOAN_SUCCESS = 'CustomerPortal:FindLoanSuccess',
  FIND_LOAN_INVALID_FLAG_FOUND = 'CustomerPortal:CustomerIneligibleBasedOnFlags',
  LOGIN_ATTEMPT = 'CustomerPortal:LoginAttempt',
  LOGIN_ATTEMPT_SUCCESS = 'CustomerPortal:LoginAttemptSuccess',
  LOGOUT = 'CustomerPortal:Logout',
  FORGOT_PASSWORD_ATTEMPT = 'CustomerPortal:ForgotPasswordAttempt',
  FORGOT_PASSWORD_FAILURE = 'CustomerPortal:ForgotPasswordFailure',
  FORGOT_PASSWORD_SUCCESS = 'CustomerPortal:ForgotPasswordSuccess',
  PAYMENT_ERROR = 'CustomerPortal:PaymentError',
  PAYMENT_SUBMITTED = 'CustomerPortal:PaymentSubmitted',
  PAYMENT_SUCCESS = 'CustomerPortal:PaymentSuccess',
  VERIFY_EMAIL = 'CustomerPortal:VerifyEmail',
  VERIFY_EMAIL_RESULT = 'CustomerPortal:VerifyEmail-Result',
  CONFIRM_INFO_REDIRECT = 'CustomerPortal:ConfirmInfoRedirectToAccountIssue',
  SIGN_DOCUMENT_CLICK_DOWNLOAD_DOCUMENT = 'CustomerPortal:SignDocuments-ClickDownloadDocument',
  SIGN_DOCUMENT_ESIGN = 'CustomerPortal:SignDocuments-Esign',
  SIGN_DOCUMENT_REJECT = 'CustomerPortal:SignDocuments-Reject',
  PERSONAL_INFORMATION_EDITED = 'CustomerPortal:ConfirmInformation-PersonalInformationEdited',
  UPDATE_PASSWORD = 'CustomerPortal:UpdatePassword',
  UTM_REFERRAL = 'CustomerPortal:UTM-Referral',
  CASHWISE_PROMO_FORM_ATTEMPT = 'CashWiseLandingPage:PromoFormAttempt',
  CASHWISE_PROMO_FORM_SUCCESS = 'CashWiseLandingPage:PromoFormSuccess',
  CASHWISE_PROMO_FORM_FAILURE = 'CashWiseLandingPage:PromoFormFailure',
  IFRAME_LOADING_DONE = 'CustomerPortal:AddOneTimeDebitIframeLoadComplete',
  IFRAME_LOADING_WARNING = 'CustomerPortal:IframeLoadTimeWarning',

  /* Page Visit events below */
  ADD_DEBIT_CARD_VISIT = 'CustomerPortal:AddDebitCard-Page-Visit',
  CONFIRM_PAYMENT_VISIT = 'CustomerPortal:Confirm-Payment-Page-Visit',
  PAYMENT_RECEIPT_VISIT = 'CustomerPortal:Payment-Receipt-Page-Visit',

  // Reactivation page visit events (in order)
  REACTIVATION_CONFIRM_INFO_VISIT = 'CustomerPortal:Reactivation-Confirm-Info-Page-Visit',
  REACTIVATION_VISIT = 'CustomerPortal:Reactivation-Options-Page-Visit',
  REACTIVATION_CONFIRM_ORIGINATION_VISIT = 'CustomerPortal:Reactivation-Confirm-Origination-Page-Visit',
  REACTIVATION_SIGN_DOCUMENTS_VISIT = 'CustomerPortal:Reactivation-SignDocuments-Page-Visit',
  REACTIVATION_SIGNED_DOCS_NOT_ELIGIBLE_FOR_FUNDLOAN = 'CustomerPortal:SignedDocumentsNotEligibleForFundLoanCall',
  REACTIVATION_FUNDLOAN_NOT_CALLED = 'CustomerPortal:CouldNotCallFundLoanOnReactivationLoan',
  COMPLETED_REACTIVATION = 'CustomerPortal:ThankYou-ReactivationCompleted',

  // Starting point for both principal/cashback flows
  CHOOSE_REFINANCE_VISIT = 'CustomerPortal:Choose-Refinance-Page-Visit',

  // Principal refinance page visit events (in order)
  PRINCIPAL_CONFIRM_INFO_VISIT = 'CustomerPortal:Refinance-Confirm-Info-Page-Visit',
  PRINCIPAL_REFINANCE_VISIT = 'CustomerPortal:Principal-Options-Page-Visit',
  PRINCIPAL_ADD_DEBIT_CARD_VISIT = 'CustomerPortal:AddDebitCard-Refinance-Page-Visit',
  PRINCIPAL_CONFIRM_ORIGINATION_VISIT = 'CustomerPortal:Refinance-Confirm-Origination-Page-Visit',
  PRINCIPAL_SIGN_DOCUMENTS_VISIT = 'CustomerPortal:Refinance-SignDocuments-Page-Visit',
  COMPLETED_REFINANCE = 'CustomerPortal:ThankYou-RefinanceCompleted',

  // Cashback refinance page visit events (in order)
  CASHBACK_CONFIRM_INFO_VISIT = 'CustomerPortal:Cashback-Confirm-Info-Page-Visit',
  CASHBACK_CONFIRMATION_VISIT = 'CustomerPortal:Cashback-Options-Page-Visit',
  CASHBACK_ADD_DEBIT_CARD_VISIT = 'CustomerPortal:AddDebitCard-Cashback-Page-Visit',
  CASHBACK_CONFIRM_ORIGINATION_VISIT = 'CustomerPortal:Cashback-Confirm-Origination-Page-Visit',
  CASHBACK_SIGN_DOCUMENTS_VISIT = 'CustomerPortal:Cashback-SignDocuments-Page-Visit',
  COMPLETED_CASHBACK = 'CustomerPortal:ThankYou-CashbackRefinanceCompleted',
}

/**
 * Enum of possible AnalyticsExceptionNames
 * This list shows all names of exceptions we track in CustomerPortal
 */
export enum AnalyticsExceptionNames {
  SIGN_DOCUMENT_ESIGN_SESSION_TIMEOUT = 'CustomerPortal:SignDocuments-EsignSessionTimeout',
  SIGN_DOCUMENT_SESSION_TIMEOUT = 'CustomerPortal:SignDocuments-SessionTimeout',
  SIGN_DOCUMENT_ERROR = 'CustomerPortal:SignDocuments-Error',
  LOGIN_FAILURE = 'CustomerPortal:Login-Failure',
}
