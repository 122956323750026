import React, {
  useCallback,
  useState,
  useRef,
  useEffect,
  useMemo,
  useContext,
} from 'react'

import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js'
import {
  Button,
  Card,
  Col,
  Container,
  Form as BootstrapForm,
  Row,
  Spinner,
} from 'react-bootstrap'
import {GoPencil} from 'react-icons/go'
import {MdChevronLeft, MdChevronRight} from 'react-icons/md'
import {Redirect, useHistory} from 'react-router-dom'
import {FormikValues} from 'formik'
import {ToastContext} from 'components/Toast'

import {ContentSlot} from 'models/Content'
import Content from 'components/Content'
import NumberStepper from 'components/NumberStepper'
import {VergentUpdateContatctInfoValues} from 'models/AccountFormValues'
import {
  RefinanceSteps,
  ReactivationSteps,
} from 'components/NumberStepper/Config'
import SpinnerButton from 'components/SpinnerButton'
import ContactReadOnly from 'components/ContactInfo/Read'
import ContactEditForm from 'components/ContactInfo/Edit'
import {useLoanData} from 'utils/hooks/useLoanData'
import {getReactivationCreacteData, getRefinanceData} from 'utils/cache'
import styles from 'pages/ConfirmInfo/ConfirmInfo.module.scss'
import {AnalyticsEventNames, AnalyticsPageNames} from 'models/Analytics'
import {getConfirmInfoPageViewEventName} from 'utils/analytics'
import {reactivationInitialConfirmation} from 'utils/edge'
import {useMutation} from 'react-query'
import {getConfigValue} from 'utils/environment'

const RefinanceConfirmInformation: React.FC = () => {
  /**
   * Analytics & Tracking
   */
  const appInsightsContext = useAppInsightsContext()
  const trackMetric = useTrackMetric(
    appInsightsContext,
    AnalyticsPageNames.CONFIRM_INFO,
  )
  useEffect(() => {
    trackMetric()
  }, [trackMetric])

  /**
   * State, Hooks
   */
  const formRef = useRef<FormikValues>(null)
  const contactFormRef = useRef<FormikValues>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [disableButton, setDisableButton] = useState(false)
  const [editing, setEditing] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const [confirmationError, setConfirmationError] = useState<string>('')
  const {
    currentLoan,
    refinanceApprovalDetails,
    reactivationApprovalDetails,
    status,
    customer: userData,
  } = useLoanData()
  const history = useHistory()
  const {pushToast} = useContext(ToastContext)
  const enableVerify =
    getConfigValue('ENABLE_VERIFICATION_BUTTON') === 'true' ? true : false
  /**
   * Memos
   */
  const refi = useMemo(() => refinanceApprovalDetails, [
    refinanceApprovalDetails,
  ])
  const refiOption = useMemo(getRefinanceData, [])?.option
  const reactivation = useMemo(() => reactivationApprovalDetails, [
    reactivationApprovalDetails,
  ])

  const reactivationInitialData = useMemo(getReactivationCreacteData, [])
  const disableNextButton = (disable: boolean) => {
    setDisableButton(disable)
  }

  const {
    mutate: reactivationInitialConfirmationMutation,
    isLoading,
  } = useMutation(reactivationInitialConfirmation, {
    onSuccess: data => {
      if (data?.isApproved) {
        history.push('/reactivate')
      } else {
        setDisableButton(true)
        pushToast({
          title: 'Unable to confirm',
          message: 'Could not complete process',
          variant: 'danger',
        })
        return history.push('/account-issue')
      }
    },
  })

  /**
   * Effects
   */
  useEffect(() => {
    let pageViewName = ''
    if (reactivation) {
      pageViewName = getConfirmInfoPageViewEventName('reactivation')
    } else if (refiOption === 'cashback') {
      pageViewName = getConfirmInfoPageViewEventName('cashback')
    } else {
      pageViewName = getConfirmInfoPageViewEventName('refinance')
    }

    if (pageViewName !== '') {
      appInsightsContext.trackEvent({
        name: pageViewName,
      })
    }
  }, [appInsightsContext, reactivation, refi, refiOption])
  useEffect(redirectIfNotEligible, [history, refinanceApprovalDetails])

  /**
   * Callbacks
   */
  const toggleEditing = useCallback(toggleCallback, [editing, setEditing])
  const goForward = useCallback(goForwardCallback, [history])
  const goBack = useCallback(goBackCallback, [history])
  const handleFormSubmit = useCallback(handleSubmitCallback, [
    history,
    confirmed,
    editing,
  ])
  const toggleConfirmed = useCallback(toggleConfirmedCallback, [
    confirmed,
    setConfirmed,
  ])

  const handleContactInfoSubmit = () => {
    if (contactFormRef.current) {
      contactFormRef.current.handleSubmit()
    }
  }

  // If the customer is eligible for reactivation, but has no debit card info on file, redirect to account issue
  if (!currentLoan) {
    appInsightsContext.trackEvent(
      {name: AnalyticsEventNames.CONFIRM_INFO_REDIRECT},
      {reactivationApprovalDetails, currentLoan},
    )
    return <Redirect to="/account-issue" />
  }

  return (
    <main>
      <Container>
        <NumberStepper
          activeStep={1}
          steps={refi ? RefinanceSteps : ReactivationSteps}
        />
        <section>
          <Card className="mb-3">
            <Card.Header>
              <h2 className="pb-2 border-medium font-weight-light d-flex justify-content-between">
                <Content type={ContentSlot.CONFIRMINFO_HEADER} />
                <Button
                  className="pr-0 ignore-block"
                  data-ref="toggle-edit-contact"
                  variant="link"
                  onClick={toggleEditing}
                >
                  {(editing && 'Cancel') || (
                    <>
                      <GoPencil />{' '}
                      <Content type={ContentSlot.CONFIRMINFO_EDITBUTTON} />
                    </>
                  )}
                </Button>
              </h2>
            </Card.Header>

            {status === 'loading' && (
              <Card.Body>
                <div className="text-center p-4">
                  <Spinner
                    animation="border"
                    className="text-primary"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              </Card.Body>
            )}
            {status === 'success' && userData && (
              <>
                <Card.Body className={`${styles.CardBody}`}>
                  <h3 className="my-3 family-semi">
                    <Content
                      type={ContentSlot.CONFIRMINFO_PERSONALINFOHEADER}
                    />
                  </h3>
                  <Row className={`${styles.Row}`}>
                    <Col>
                      <p className="mb-1">Name</p>
                      <p>
                        <b>
                          {userData.firstName} {userData.lastName}
                        </b>
                      </p>
                    </Col>
                  </Row>
                  {(editing && (
                    <>
                      <ContactEditForm
                        formRef={contactFormRef}
                        navigate={undefined}
                        setIsSubmitting={setIsSubmitting}
                        toggleEditMode={toggleEditing}
                        userData={userData}
                      />
                      <Row className="justify-content-center">
                        <Col className={`${styles.Row}`} lg="auto" xs="12">
                          <SpinnerButton
                            block
                            color="primary"
                            loading={isSubmitting}
                            size="lg"
                            onClick={handleContactInfoSubmit}
                          >
                            Update
                          </SpinnerButton>
                        </Col>
                      </Row>
                    </>
                  )) || (
                    <ContactReadOnly
                      disableNextButtton={disableNextButton}
                      showVerifyButton={enableVerify}
                      userData={userData}
                    />
                  )}
                  {!refi && (
                    <>
                      <h3 className={`${styles.IncomeHeader} family-semi`}>
                        <Content
                          type={ContentSlot.CONFIRMINFO_EMPLOYMENTINFOHEADER}
                        />
                      </h3>
                      {userData.customerEmployers.map((item, idx) => (
                        <div
                          key={idx}
                          className={`${styles.EmploymentConfirmationDiv}`}
                        >
                          <p className="my-0">
                            <strong>Employer: </strong>
                            {item.employerName}
                          </p>
                          <p className="my-0">
                            <strong>Payment Cycle: </strong>
                            {item.frequencyOfPay}
                          </p>
                        </div>
                      ))}
                      <BootstrapForm.Group
                        className={`${styles.EmploymentConfirmationDiv}`}
                        controlId="employment"
                      >
                        <Container className={`${styles.CheckboxContainer}`}>
                          <BootstrapForm.Check
                            data-ref="confirm"
                            label={
                              <Content
                                type={
                                  ContentSlot.CONFIRMINFO_EMPLOYMENTINFOCHECKBOX
                                }
                              />
                            }
                            name="confirm"
                            type="checkbox"
                            onChange={toggleConfirmed}
                          />
                          {!confirmed && (
                            <BootstrapForm.Text className="text-danger">
                              {confirmationError}
                            </BootstrapForm.Text>
                          )}
                          <Row className="p-2">
                            <Content
                              primaryLinkColor
                              type={
                                ContentSlot.EMPLOYERINFORMATIONCHANGED_LINK
                              }
                            />
                          </Row>
                        </Container>
                      </BootstrapForm.Group>
                    </>
                  )}
                </Card.Body>
                <Card.Footer>
                  <Container>
                    <Row className="mt-3 justify-content-md-between align-items-center">
                      <Col className="col-12 col-md-6 order-2 order-md-1 p-3 p-md-0 text-center text-md-left">
                        <Button
                          className="pl-0 text-dark"
                          data-ref="back-button"
                          variant="link"
                          onClick={goBack}
                        >
                          <MdChevronLeft width=".75em" />
                          <Content type={ContentSlot.CONFIRMINFO_BACKBUTTON} />
                        </Button>
                      </Col>
                      <Col className="col-12 col-md-6 p-0 order-1 order-md-2 text-right">
                        {disableButton && enableVerify ? (
                          <SpinnerButton
                            color="light"
                            disabled={disableButton}
                            size="lg"
                            variant="light"
                          >
                            <Content
                              type={ContentSlot.CONFIRMINFO_NEXTBUTTON}
                            />
                            <MdChevronRight width=".75em" />
                          </SpinnerButton>
                        ) : (
                          <SpinnerButton
                            color="primary"
                            data-ref="submit"
                            loading={isLoading}
                            size="lg"
                            onClick={handleFormSubmit}
                          >
                            <Content
                              type={ContentSlot.CONFIRMINFO_NEXTBUTTON}
                            />
                            <MdChevronRight width=".75em" />
                          </SpinnerButton>
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Card.Footer>
              </>
            )}
          </Card>
        </section>
      </Container>
    </main>
  )

  /**
   * Toggles on "edit" mode
   */
  function toggleCallback() {
    setEditing(!editing)
  }

  /**
   * Navigates to next screen
   * @param values update contact form values
   */
  function goForwardCallback(values?: VergentUpdateContatctInfoValues) {
    if (values && currentLoan?.storeState !== values.state) {
      history.push('/home')
    } else if (refi?.hasCashbackOption && refi.hasRefinanceOption) {
      history.push('/choose-refinance')
    } else if (refiOption === 'cashback') {
      history.push('/cash-back')
    } else if (refiOption === 'refinance') {
      history.push('/principal-refinance')
    } else {
      reactivationInitialConfirmationMutation({reactivationInitialData})
    }
  }

  /**
   * Redirect to home if user is not eligilble or tries to enter page without entering cashback or refinance flow
   */
  function redirectIfNotEligible() {
    // Eligible for Reactivation
    if (currentLoan?.todaysPayoffAmount === 0 && reactivation) return
    // Not Eligible for Refinance
    if (!refi?.hasCashbackOption && !refi?.hasRefinanceOption) {
      history.push('/home')
    }
  }

  /**
   * Navigates to prev screen
   */
  function goBackCallback() {
    history.goBack()
  }

  /**
   * Handles the submission
   */
  function handleSubmitCallback() {
    // Reactivation Flow is the only scenario we should check that the user confirmed their employment information
    if (!refi && !confirmed) {
      setConfirmationError(
        'You must confirm your income information to continue.',
      )
      return
    }
    // If the user has edited their information, regardless of which flow they are in, submit the api call to do so.
    if (editing && formRef.current) {
      formRef.current.handleSubmit()
      return
    }
    // If they haven't edited their information, navigate to the next page.
    goForward()
  }

  /**
   * Alternates between checked/unchecked
   */
  function toggleConfirmedCallback() {
    setConfirmed(!confirmed)
  }
}

/**
 * Allows the user to confirm/update their personal contact information for Reactivation, Refinance, and Cashback
 */
export default RefinanceConfirmInformation
