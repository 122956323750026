import React, {useCallback, useContext, useEffect, useState} from 'react'
import styles from './VerifyNumberPopup.module.scss'
import {RequestPinByTextBody, VerifyPinRequest} from '~/models/Edge'
import {getRequestPinByText, verifyPin} from 'utils/edge'
import {ToastContext} from 'components/Toast'
import {Button, Modal, Col, Row, Form as BootstrapForm} from 'react-bootstrap'
import {getConfigValue} from 'utils/environment'
import {MdClose} from 'react-icons/md'

interface VerifyNumberPopupProps {
  phoneNumber: string | undefined
  visility: boolean
  toggleVisiblity(visibility: boolean): void
  hideBeginVerificationButton(): void
  disableNextButton(disable: boolean): void
  setVerificationButtonIsLoanding(isLoanding: boolean): void
}

const VerifyNumberPopup: React.FC<VerifyNumberPopupProps> = props => {
  const {pushToast} = useContext(ToastContext)
  const [pin, setPin] = useState('')
  const [verifyDisabled, setVerifyDisabled] = useState(false)
  const [isTimerReset, setIsTimerReset] = useState(false)
  const [failedAttempts, setFailedAttempts] = useState(0)
  const vergentCounerValue = getConfigValue('VERIFICATION_COUNTER_VALUE')
    ? parseInt(getConfigValue('VERIFICATION_COUNTER_VALUE'))
    : 60
  const [counter, setCounter] = useState(vergentCounerValue)
  const resetForm = useCallback(() => {
    setVerifyDisabled(false)
    setCounter(vergentCounerValue)
    setFailedAttempts(0)
    setPin('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClose = useCallback(() => {
    props.toggleVisiblity(false)
    resetForm()
    props.setVerificationButtonIsLoanding(false)
  }, [props, resetForm])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => resetForm(), [props.visility])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (counter > 0) {
        setCounter(counter - 1)
      }

      if (counter <= 0) {
        if (!isTimerReset && props.visility) {
          pushToast({
            title: 'Verification Code Expired',
            message: `Your verification code has expired. Select Resend to get a new code.`,
            variant: 'danger',
          })
        }
        setVerifyDisabled(true)
      }
    }, 1000)
    return () => clearTimeout(timer)
  }, [counter, isTimerReset, props.visility, pushToast])

  const handleResend = async () => {
    const values: RequestPinByTextBody = {
      phoneNumber: props.phoneNumber ?? '',
      type: 2,
    }
    resetForm()
    const pinByTextData = await getRequestPinByText(values)

    if (pinByTextData) {
      setCounter(vergentCounerValue)
      if (pinByTextData.result) {
        resetForm()
        return
      }
      pushToast({
        title: 'Unable to resend',
        message: `We were not able to resend the pin code to the provided phone number. Please try again`,
        variant: 'danger',
      })
    }
  }

  const handleVerifyPin = async () => {
    const values: VerifyPinRequest = {
      phoneNumber: props.phoneNumber ?? '',
      pin,
      type: 2,
    }
    const verifyPinData = await verifyPin(values)

    if (verifyPinData) {
      if (verifyPinData.result) {
        props.hideBeginVerificationButton()
        props.disableNextButton(false)
        props.toggleVisiblity(false)
        setIsTimerReset(true)
        return
      }
      setFailedAttempts(failedAttempts + 1)
      if (failedAttempts >= 2) {
        const storePhoneNumber =
          localStorage.getItem('userStorePhoneNumber') ??
          getConfigValue('DEFAULT_PHONE_NUMBER')
        pushToast({
          title: 'Invalid verification code entered',
          message: `You will be redirected to the Verify Your Information page to restart the cell phone verification process.
          Completion of this step is necessary to proceed. If you need assistance with this step, please contact your local Cash Store at ${storePhoneNumber}`,
          variant: 'danger',
        })
        handleClose()
        return
      }
      pushToast({
        title: 'Invalid verification code entered',
        message: `Please re-enter the code you received or select Resend to get a new verification code.`,
        variant: 'danger',
      })
    }
  }

  const handlePinChange = (event: {
    target: {value: React.SetStateAction<string>}
  }) => {
    setPin(event.target.value)
  }

  const formatTimeToMinsAndSecs = (seconds: number) =>
    new Date(seconds * 1000).toISOString().slice(14, 19)

  const handleKeypress = (e: {keyCode: number}) => {
    if (e.keyCode === 13) {
      handleVerifyPin()
    }
  }

  return (
    <Modal
      centered
      backdrop="static"
      dialogClassName="verify-modal"
      keyboard={false}
      show={props.visility}
      size="lg"
      onHide={handleClose}
    >
      <div>
        <MdClose
          className={`${styles.CloseGlyph}`}
          size="1.5rem"
          onClick={handleClose}
        />
      </div>
      <Modal.Body className={`${styles.ModalBody}`}>
        <Row>
          <div className={` ${styles.ModalHeader}`}>
            <h3>Enter Verification Code</h3>
            <div
              className={`${styles.desriptionTextContainer} border-bottom border-dark`}
            >
              Select Verify after entering your code. If you need a new code,
              select Resend.
            </div>
          </div>
        </Row>
        <Row className={`${styles.inputRow}`}>
          <div>
            <BootstrapForm.Control
              className="form-control"
              data-ref="code"
              name="code"
              type="text"
              value={pin}
              onChange={handlePinChange}
              onKeyDown={handleKeypress}
            />
          </div>
          <div
            className={
              counter > 0
                ? ` ${styles.Counter}`
                : ` ${styles.Counter} ${styles.CounterExpired}`
            }
          >
            {formatTimeToMinsAndSecs(counter)}
          </div>
        </Row>
        <div className={` ${styles.ButtonContainer}`}>
          <Row>
            <Col>
              <Button
                className={`${styles.PopUpButton}`}
                disabled={verifyDisabled}
                variant="primary"
                onClick={handleVerifyPin}
              >
                Verify
              </Button>
            </Col>
            <Col>
              <Button
                className={`${styles.PopUpButton}`}
                variant="primary"
                onClick={handleResend}
              >
                Resend
              </Button>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  )
}

/**
 * VerifyNumberPopup Component for displaying messages.
 */
export default VerifyNumberPopup
