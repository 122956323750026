import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useContext,
} from 'react'

import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js'
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  Form as BootstrapForm,
} from 'react-bootstrap'
import {Redirect, useHistory} from 'react-router-dom'
import {GoChevronLeft, GoChevronRight} from 'react-icons/go'
import * as Yup from 'yup'
import {Form, Formik, FormikProps, useFormikContext} from 'formik'
import FormikErrorFocus from 'formik-error-focus'
import MaskedInput from 'react-text-mask'
import {useMutation} from 'react-query'

import {getLoanScheduleRequest} from 'utils/edge'
import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import NumberStepper from 'components/NumberStepper'
import {RefinanceSteps} from 'components/NumberStepper/Config'
import PageSpinner from 'components/PageSpinner'
import LoanSchedule from 'components/LoanSchedule'
import {useLoanData} from 'utils/hooks/useLoanData'
import {useRefinanceData} from 'utils/hooks/useRefinanceData'
import {
  CashbackFormValues,
  PaymentMethodTypes,
  GetLoanScheduleValues,
  ConfirmRefinanceLoanValues,
} from 'models/Edge'
import {formatCurrency, formatCurrencyToFloat} from 'utils/data-formatting'
import DebitCardsDropdown from 'components/Form/DebitCardsDropdown'
import {
  setRefinanceData,
  setPartialPaymentData,
  setCashbackData,
  getRefinanceData,
  setPartialRefinancePaymentData,
  setCashbackAmount,
} from 'utils/cache'
import {currencyMask} from 'utils/forms/masks'
import {
  dateSortAsc,
  getFormatedFullDate,
  largeScreenFormattedDate,
  mobileScreenFormattedDate,
} from 'utils/dates'
import {Breakpoints} from 'models/Breakpoints'
import {AnalyticsEventNames, AnalyticsPageNames} from 'models/Analytics'
import {ToastContext} from 'components/Toast'
import {useAuth} from 'auth'
import {useDebounce} from 'utils/hooks/useDebounce'
import {OfferSelector} from 'components/Form/OfferSelector'

const CashBackPayment: React.FC<{
  showNumberStepper?: boolean
}> = ({showNumberStepper = true}) => {
  /**
   * Analytics & Tracking
   */
  const appInsightsContext = useAppInsightsContext()
  const trackMetric = useTrackMetric(
    appInsightsContext,
    AnalyticsPageNames.CASHBACK,
  )
  useEffect(() => {
    trackMetric()
  }, [trackMetric])

  useEffect(() => {
    appInsightsContext.trackEvent({
      name: AnalyticsEventNames.CASHBACK_CONFIRMATION_VISIT,
    })
  }, [appInsightsContext])

  /**
   * Hooks
   */
  const {pushToast} = useContext(ToastContext)
  const history = useHistory()
  const {
    customer,
    currentLoan,
    fullName,
    refinanceApprovalDetails,
    setCardInfo,
    status: loanStatus,
  } = useLoanData()
  const {
    cashbackApproved,
    refiOptions,
    status: cashbackOptStatus,
  } = useRefinanceData()
  const [loanSchedulePayload, setLoanSchedulePayload] = useState<
    GetLoanScheduleValues
  >()
  const isDisbursementCards =
    customer?.customerCards.filter(card => card.isEligibleForDisbursement) ??
    []
  const {user} = useAuth()

  /**
   * Handles First Payment Date option value based on screen size
   */
  const [width, setWidth] = React.useState(window.innerWidth)
  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  /**
   * Callbacks, mutations
   */
  const goBack = useCallback(goBackCallback, [history])
  const submitFormValues = useCallback(submitFormValuesCallback, [refiOptions])
  const {
    mutate: requestLoanSchedule,
    isLoading: loanScheduleLoading,
    isSuccess: loanScheduleSuccess,
    data: loanScheduleData,
    error: loanScheduleError,
    isLoading: isLoanDataLoading,
  } = useMutation(getLoanScheduleRequest)
  /**
   * Variables
   */
  const cashbackOption = refiOptions?.cashback
  const maxCashbackAmount = `${formatCurrency(
    cashbackOption?.cashbackAmount ?? 0,
  )}`
  const minPaymentAmount = `${formatCurrency(
    cashbackOption?.requiredToPay ?? 0,
  )}`

  /**
   * Memos
   */
  const refi = useMemo(getRefinanceData, [])
  const initialFormValues = useMemo(
    () =>
      ({
        cashbackAmount: formatCurrency(
          refi?.cashback?.cashbackAmount ??
            cashbackOption?.cashbackAmount ??
            0,
        ),
        paymentAmount:
          refi?.refinance?.paymentAmount?.toString() ?? minPaymentAmount,
        paymentMethod: PaymentMethodTypes.NULL,
        disbursementMethod: PaymentMethodTypes.NULL,
        cvv: '',
        firstPaymentDate:
          refinanceApprovalDetails?.suggestedFirstPaymentDates.length === 1
            ? refinanceApprovalDetails.suggestedFirstPaymentDates[0]
            : '',
      } as CashbackFormValues),
    [cashbackOption, minPaymentAmount, refi, refinanceApprovalDetails],
  )
  const minimumAmount = 0.5
  const FormSchema = Yup.object().shape({
    cashbackAmount: Yup.string()
      .required('Cash Back amount is required')
      .test(
        'max-cashback-amount',
        `You can only receive up to ${maxCashbackAmount}`,
        (value: string) => {
          if (value === undefined) return true
          return (
            formatCurrencyToFloat(value) <=
            formatCurrencyToFloat(maxCashbackAmount)
          )
        },
      )
      .required('Cash Back amount minimum must be $0.50')
      .test(
        'minimum-cashback-amount',
        'You can only receive a minimum of $0.50',
        (value: string) => {
          if (value === undefined) return true
          if (parseInt(value) < minimumAmount) return true
          return formatCurrencyToFloat(value) >= minimumAmount
        },
      ),
    paymentMethod: Yup.string().required('Payment method is required'),
    disbursementMethod: Yup.string().required(
      'Disbursement method is required',
    ),
    cvv: Yup.string().when('paymentMethod', (paymentMethod: string) => {
      const matchExistingPaymentMethod = /^(.+?):/
      const isExistingCard = matchExistingPaymentMethod.exec(paymentMethod)

      if (isExistingCard) {
        return Yup.string()
          .required('CVV is required')
          .test(
            'three-digit-number',
            'CVV number must be 3 digits long',
            (value: string) => {
              if (value === undefined) return true
              return value.replace(/\D/g, '').length === 3
            },
          )
      }
    }),
    firstPaymentDate: Yup.string().required('First payment date is required'),
  })

  if (loanStatus === 'loading' || cashbackOptStatus === 'loading') {
    return <PageSpinner />
  }

  if (!cashbackApproved) {
    return <Redirect to="/home" />
  }

  return (
    <Container>
      {showNumberStepper && (
        <NumberStepper activeStep={2} steps={RefinanceSteps} />
      )}
      <Card className="mb-3 full-page">
        <Card.Header>
          <h1>
            <Content type={ContentSlot.CASHBACK_HEADER} />
          </h1>
          <h5>
            <Content type={ContentSlot.CASHBACK_DESCRIPTION} />{' '}
            {maxCashbackAmount} in cash.
          </h5>
          <h5>
            <Content type={ContentSlot.REFINANCE_SECONDARYDESCRIPTION} />
          </h5>
        </Card.Header>
        <Formik
          initialValues={initialFormValues}
          validationSchema={FormSchema}
          onSubmit={submitFormValues}
        >
          {({
            values,
            handleBlur,
            handleChange,
            setFieldValue,
            touched,
            errors,
          }: FormikProps<CashbackFormValues>) => {
            const handleAddCard = () => {
              setCashbackAmount(formatCurrencyToFloat(values.cashbackAmount))
            }
            const handleSelectOffer = (offerId: number) => {
              setFieldValue('offerId', offerId)
            }
            return (
              <Form>
                <SideEffect />
                <OfferSelector
                  disabled
                  offers={
                    customer?.refinanceModelOption?.map(refiModelOption => ({
                      id: refiModelOption.loanModelId,
                      name: refiModelOption.loanModelName,
                      range: {
                        max: refiModelOption.maxAmount,
                        min: refiModelOption.minAmount,
                      },
                    })) ?? []
                  }
                  selectedOfferId={
                    customer?.refinanceModelOption[0].loanModelId ?? 0
                  }
                  setSelectedOfferId={handleSelectOffer}
                />
                <Card.Body>
                  <Row>
                    <Col lg="6" xs="12">
                      <BootstrapForm.Group controlId="cashbackAmount">
                        <BootstrapForm.Label>
                          How much cash would you like to receive?
                        </BootstrapForm.Label>
                        <MaskedInput
                          className="form-control"
                          data-ref="cashback-amount"
                          inputMode="decimal"
                          mask={currencyMask}
                          name="cashbackAmount"
                          type="text"
                          value={values.cashbackAmount}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {touched.cashbackAmount && errors.cashbackAmount && (
                          <BootstrapForm.Text className="text-danger">
                            {errors.cashbackAmount}
                          </BootstrapForm.Text>
                        )}
                      </BootstrapForm.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" xs="12">
                      <DebitCardsDropdown
                        isDisbursement
                        showAddCard
                        cardInfo={isDisbursementCards}
                        cvv={values.cvv}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        idSuffix=""
                        paymentMethod={values.disbursementMethod}
                        touched={touched}
                        onAddCard={handleAddCard}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <p>
                        *If you do not wish to enroll in automatic payments,
                        please visit one of our store locations to complete
                        your loan origination.
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" xs="12">
                      <BootstrapForm.Group controlId="firstPaymentDate">
                        <BootstrapForm.Label>
                          First Payment Date
                        </BootstrapForm.Label>
                        <BootstrapForm.Control
                          as="select"
                          data-ref="first-payment-date"
                          name="firstPaymentDate"
                          value={values.firstPaymentDate}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          <option key="default" disabled value="">
                            Select a Date
                          </option>
                          {refinanceApprovalDetails?.suggestedFirstPaymentDates
                            .sort(dateSortAsc)
                            .map((date, index) =>
                              width > Breakpoints.mobile ? (
                                <option key={index} value={date}>
                                  {largeScreenFormattedDate(date)}
                                </option>
                              ) : (
                                <option key={index} value={date}>
                                  {mobileScreenFormattedDate(date)}
                                </option>
                              ),
                            )}
                        </BootstrapForm.Control>

                        {touched.firstPaymentDate &&
                          errors.firstPaymentDate && (
                            <BootstrapForm.Text className="text-danger">
                              {errors.firstPaymentDate}
                            </BootstrapForm.Text>
                          )}
                      </BootstrapForm.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <LoanSchedule
                        cashBackAmount={formatCurrencyToFloat(
                          values.cashbackAmount,
                        )}
                        error={loanScheduleError as Error | null | undefined}
                        isLoading={loanScheduleLoading || isLoanDataLoading}
                        isSuccess={loanScheduleSuccess}
                        loanSchedule={loanScheduleData?.loanSchedule}
                        maxCashBackAmount={cashbackOption?.cashbackAmount}
                        startDate={new Date()}
                        tilaDisclosure={
                          loanScheduleData?.tilaDisclosureDetailsDto
                        }
                        timePeriod={
                          refinanceApprovalDetails?.periodLengthField
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" xs="12">
                      <p className="font-weight-bold mb-3 mt-2">
                        Your minimum payment today is {minPaymentAmount}.
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" xs="12">
                      <DebitCardsDropdown
                        showOneTimeCard
                        cardInfo={customer?.customerCards}
                        cvv={values.cvv}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        idSuffix=""
                        paymentMethod={values.paymentMethod}
                        touched={touched}
                      />
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <Container>
                    <Row className="justify-content-between">
                      <Col className="col-12 col-md-6 order-2 order-md-1 p-3 p-md-0 text-center text-md-left">
                        <Button
                          className="pl-0 text-dark"
                          variant="link"
                          onClick={goBack}
                        >
                          <GoChevronLeft />{' '}
                          <Content type={ContentSlot.CASHBACK_BACKBUTTON} />
                        </Button>
                      </Col>
                      <Col className="col-12 col-md-6 p-0 order-1 order-md-2 text-right">
                        <Button data-ref="submit" type="submit">
                          <Content type={ContentSlot.CASHBACK_NEXTBUTTON} />{' '}
                          <GoChevronRight />
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </Card.Footer>
                <FormikErrorFocus
                  align="middle"
                  duration={500}
                  ease="linear"
                  focusDelay={200}
                />
              </Form>
            )
          }}
        </Formik>
      </Card>
    </Container>
  )

  /**
   * Navigates to prev screen
   */
  function goBackCallback() {
    history.goBack()
  }

  /**
   * Auto request loan schedule if user inupts complete request payload
   * @return null
   */
  function SideEffect() {
    const {
      values: undebouncedValues,
      errors: undebouncedErrors,
    } = useFormikContext<CashbackFormValues>()
    const values = useDebounce(undebouncedValues, 750)
    const errors = useDebounce(undebouncedErrors, 750)

    useEffect(() => {
      if (
        errors.firstPaymentDate?.length ||
        errors.paymentAmount?.length ||
        errors.cashbackAmount?.length ||
        !refinanceApprovalDetails ||
        !currentLoan
      )
        return

      const paymentAmount = formatCurrencyToFloat(values.paymentAmount)
      const cashbackAmount = formatCurrencyToFloat(values.cashbackAmount)
      const loanAmount =
        currentLoan.todaysPayoffAmount - paymentAmount + cashbackAmount
      const {firstPaymentDate} = values

      if (
        isNaN(paymentAmount) ||
        isNaN(cashbackAmount) ||
        isNaN(loanAmount) ||
        !values.firstPaymentDate.length ||
        cashbackAmount < minimumAmount
      )
        return

      if (
        loanAmount === loanSchedulePayload?.loanAmount &&
        firstPaymentDate === loanSchedulePayload.firstPaymentDate
      )
        return

      const payload: GetLoanScheduleValues = {
        firstPaymentDate,
        loanAmount,
        numberOfPeriods: refinanceApprovalDetails.numberOfPeriods,
        loanId: currentLoan.loanId,
        amountRequested: cashbackAmount + currentLoan.currentBalance,
        apr: currentLoan.originalFeeApr,
        currentBalance: currentLoan.currentBalance,
        feeBalance: currentLoan.feeBalance,
      }
      const todaysDate = getFormatedFullDate(new Date())

      let bankId = 0
      if (customer && customer.customerBankAccounts.length > 0) {
        bankId = customer.customerBankAccounts[0].id
      }

      let refiCardId = 0
      if (customer && customer.customerCards.length > 0) {
        refiCardId = customer.customerCards[0].id
      }

      const valuesToLoanEstimate: ConfirmRefinanceLoanValues = {
        customerId: Number(user?.personReferenceNumber),
        companyId: customer?.companyId ?? 0,
        storeId: customer?.refinanceModelOption[0].storeId ?? 0,
        loanModelId: customer?.refinanceModelOption[0].loanModelId ?? 0,
        cosignerId: 0,
        originationDate: todaysDate,
        fundingDate: todaysDate,
        loanAmount,
        dueDate: firstPaymentDate,
        numberOfPayments: 0,
        bankId,
        fundingMethod: 'CARD',
        fundingLocation: 'NotSpecified',
        workflowId: 0,
        autopayEnrollment: {
          enrollAutopay: true,
          autopayType: 'ACH',
          autopayCardId: 0,
          autopayBankId: bankId,
        },
        initialLoanStatus: 'None',
        initialDrawAmount: 0,
        refinanceData: [
          {
            headerId: currentLoan.loanId,
            loanModelId: currentLoan.loanModelId,
            storeId: Number(currentLoan.storeId),
            payment: {
              amount: paymentAmount,
              paymentDate: todaysDate,
              paymentMethod: 'CARD',
              bankId,
              cardId: refiCardId,
            },
          },
        ],
      }
      requestLoanSchedule({valuesToLoanEstimate, payload})
      setLoanSchedulePayload(payload)
    }, [
      errors.cashbackAmount,
      errors.firstPaymentDate,
      errors.paymentAmount,
      values,
    ])

    return null
  }

  /**
   * Submit form values
   * @param values form values
   * @return url redirect
   */
  function submitFormValuesCallback(values: CashbackFormValues) {
    const {cvv} = values
    const cashbackAmount = formatCurrencyToFloat(values.cashbackAmount)
    const paymentAmount = formatCurrencyToFloat(values.paymentAmount)
    const {firstPaymentDate} = values

    if (!currentLoan?.refinanceApprovalDetails?.paymentFrequencyId) {
      pushToast({
        title: 'Insufficient information to continue, please contact support',
        variant: 'danger',
      })
      return history.push('/refinance-issue', {
        from: history.location.pathname,
      })
    }

    const currentCard = customer?.customerCards?.find(
      card => card.id === Number(values.paymentMethod.replace(/[^0-9]+/g, '')),
    )

    const disbursementCard = customer?.customerCards?.find(
      card =>
        card.id === Number(values.disbursementMethod.replace(/[^0-9]+/g, '')),
    )

    const card = {
      cardCvv: cvv,
      cardType: currentCard?.cardType ?? '',
      cardToken: currentCard?.cardTypeId ?? 0,
      cardTokenLast4: currentCard?.accountNumberMasked ?? '',
      cardIsFundable: currentCard?.isExpired,
    }

    setCardInfo({
      cardType: disbursementCard?.cardType ?? '',
      collateralCreditCardToken: disbursementCard?.cardTypeId ?? 0,
      collateralCreditCardTokenLast4:
        disbursementCard?.accountNumberMasked ?? '',
      contactId: disbursementCard?.id ?? 0,
      isFundable: Boolean(disbursementCard?.isExpired),
    })

    setCashbackData({
      ...card,
      cashbackAmount,
      paymentAmount,
      disbursementLast4: disbursementCard?.accountNumberMasked ?? '',
      disbursementType: disbursementCard?.cardType ?? '',
    })

    const formatedPaymentMethod = values.paymentMethod.replace(/:.*$/, '')
    switch (formatedPaymentMethod) {
      case PaymentMethodTypes.EXISTING_CARD:
        setRefinanceData({
          ...card,
          cardId: currentCard?.id,
          paymentAmount,
          customerName: fullName,
          companyId: customer?.companyId,
          storeId: currentLoan.refinanceApprovalDetails.refiStoreId.toString(),
          firstPaymentDate,
          paymentFrequencyId:
            currentLoan.refinanceApprovalDetails.paymentFrequencyId,
          loanModelId: currentLoan.refinanceApprovalDetails.refiLoanModelId,
          disbursementCardId: disbursementCard?.id,
        })
        return history.push('/confirm-origination', {
          from: history.location.pathname,
        })
      case PaymentMethodTypes.ONE_TIME_CARD:
        setPartialRefinancePaymentData({
          firstPaymentDate,
          paymentFrequencyId:
            currentLoan.refinanceApprovalDetails.paymentFrequencyId,
          companyId: customer?.companyId,
          storeId: currentLoan.refinanceApprovalDetails.refiStoreId.toString(),
          loanModelId: currentLoan.refinanceApprovalDetails.refiLoanModelId,
          disbursementCardId: disbursementCard?.id,
        })
        setPartialPaymentData(paymentAmount, fullName)
        return history.push('/add-card-and-pay#one-time', {
          from: history.location.pathname,
        })
      default:
    }
  }
}

/**
 * Cash Back Payment Page
 */
export default CashBackPayment
