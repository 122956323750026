const USERNAME_KEY_PREFIX = 'username_'

/**
 * Saves the username from the local storage
 * @param username from the auth info
 * @param personReferenceNumber from the auth info
 * @return void
 */
export const saveUsername = (
  username: string,
  personReferenceNumber: string,
) =>
  localStorage.setItem(
    `${USERNAME_KEY_PREFIX}${personReferenceNumber}`,
    username,
  )

/**
 * Gets the username from the local storage
 * @param personReferenceNumber from the auth info
 * @return void
 */
export const getUsername = (personReferenceNumber: string) =>
  localStorage.getItem(`${USERNAME_KEY_PREFIX}${personReferenceNumber}`)
