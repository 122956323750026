import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react'
import {RiCloseCircleLine} from 'react-icons/ri'
import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js'
import {Button, Card, Col, Container, Row} from 'react-bootstrap'
import {useMutation, useQuery} from 'react-query'
import {Redirect, useHistory} from 'react-router-dom'

import PageSpinner from 'components/PageSpinner'
import {
  getPaymentData,
  setPaymentData,
  PaymentData,
  getRefinanceData,
  getReactivationData,
  updateLoanDetails,
} from 'utils/cache'
import {addCustomerCard, getDebitCardFormUrl, getProfile} from 'utils/edge'
import {useLoanData} from 'utils/hooks/useLoanData'
import {MdChevronLeft} from 'react-icons/md'

import styles from 'pages/AddDebitCard/AddDebitCard.module.scss'
import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import {DebitCardUrl} from 'models/Edge'
import {AnalyticsPageNames, AnalyticsEventNames} from 'models/Analytics'
import {ToastContext} from 'components/Toast'
import {getConfigValue} from 'utils/environment'
import {getAddDebitCardPageViewEventName} from 'utils/analytics'
import {useStoreDetails} from 'utils/hooks/useStoreDetails'
import {useAuth} from 'auth'
import {useGetCardType} from 'utils/hooks/useGetCustomerCardTypes'
import {CreateCreditCardFormValues} from 'models/AccountFormValues'
import {TypeCreditCard} from 'models/PaymentMethods'

interface DebitCardData {
  paymentData: {
    card_last_four: string
    card_expiration: string
  }
  paymentResponseData: {
    saved_payment_method: {
      token: string
      is_eligible_for_disbursement: boolean
    }
    name_on_card: string
    payment_type_id: TypeCreditCard
  }
}

interface RouteState {
  from: string
}

const AddDebitCard: React.FC = () => {
  /**
   * Analytics & Tracking
   */
  const appInsightsContext = useAppInsightsContext()
  const trackMetric = useTrackMetric(
    appInsightsContext,
    AnalyticsPageNames.ADD_DEBIT_CARD,
  )
  useEffect(() => {
    trackMetric()
  }, [trackMetric])

  /**
   * Context
   */
  const history = useHistory<RouteState | undefined>()
  const {pushToast, clearAllToasts} = useContext(ToastContext)
  /**
   * State, Hooks
   */
  const refi = useMemo(getRefinanceData, [])
  const reactivation = useMemo(getReactivationData, [])
  const [iframeStartLoadTime, setIframeStartLoadTime] = useState(0)
  const [iframeHasLoaded, setIframeHasLoaded] = useState(false)
  const [iframeFinishedLoadingTime, setIframeFinishedLoadingTime] = useState(0)
  const [loadingLimitReached, setLoadingLimitReached] = useState(false)
  const [retryAttempts, setRetryAttempts] = useState(0)
  const [refetchAttempt, setRefetchAttempt] = useState(false)
  const [timer, setTimer] = useState<NodeJS.Timeout>()
  const [showError, setShowError] = useState(false)
  const {data: loanDetails, customer, currentLoan} = useLoanData()
  const {data: store} = useStoreDetails(currentLoan?.originatingStore)
  const [isSuccessfullyAdd, setIsSuccessfullyAdd] = useState(false)

  // Disbursement logic values
  const [showDisbursementError, setShowDisbursementError] = useState(false)
  const isDisbursmentCard = useMemo(
    () => history.location.hash === '#disbursement',
    [history.location.hash],
  )

  const paymentData = getPaymentData() as PaymentData
  const [urlDetails, setUrlDetails] = useState<DebitCardUrl>()
  const {refetch, isLoading: isLoadingUrl} = useQuery(
    [
      'debitCardUrl',
      loanDetails?.currentLoanDetails.originatingStore,
      customer?.personId,
      loanDetails?.customerDetails.customerMobileProfileId,
    ],
    async _context => {
      if (
        !loanDetails?.customerDetails.personReferenceNumber ||
        !store?.state
      ) {
        const toastTitle = loanDetails?.customerDetails.personReferenceNumber
          ? 'No Store State Found'
          : 'Invalid user'

        pushToast({
          title: toastTitle,
          variant: 'danger',
        })
        return history.push('/refinance-issue', {
          from: history.location.pathname,
        })
      }
      setUrlDetails(
        await getDebitCardFormUrl(
          loanDetails.customerDetails.personReferenceNumber,
          loanDetails.customerDetails.customerMobileProfileId,
          store.state,
          0,
        ),
      )
    },
    {
      cacheTime: 0,
    },
  )
  const {
    data: {data: cardTypes = {}} = {},
    isLoading: cardTypesLoading,
  } = useGetCardType()

  const {user} = useAuth()

  const {mutate: addCard} = useMutation(addCustomerCard, {
    onSuccess: async response => {
      if (response) {
        setShowError(false)
        setIsSuccessfullyAdd(true)
        updateLoanDetails({
          customerId: customer?.personReferenceNumber ?? '',
          getNewLoanDetails: prevLoanDetails => ({
            ...prevLoanDetails,
            customerDetails: {
              ...prevLoanDetails.customerDetails,
              customerCards: [
                ...prevLoanDetails.customerDetails.customerCards,
                response.newCard,
              ],
            },
          }),
        })
      }
      if (customer?.personReferenceNumber && user?.username) {
        await getProfile(customer.personReferenceNumber, user.username)
      }
    },
    onError: () => {
      setShowDisbursementError(false)
      setShowError(true)
      pushToast({
        title: 'Unable to create card',
        variant: 'danger',
      })
    },
  })

  /**
   * Callbacks
   */

  const goBack = useCallback(goBackCallback, [history])
  const handleAddNewCard = useCallback(() => {
    refetch()
    setShowError(false)
    setShowDisbursementError(false)
  }, [refetch])

  /**
   * Effects
   */
  useEffect(handleIframeMessage, [paymentData, cardTypesLoading])
  useEffect(() => {
    if (showError && !isSuccessfullyAdd) {
      refetch()
    }
  }, [isSuccessfullyAdd, refetch, showError])
  useEffect(() => {
    let pageViewName = ''
    if (!refi) {
      pageViewName = getAddDebitCardPageViewEventName()
    } else if (refi.option === 'cashback') {
      pageViewName = getAddDebitCardPageViewEventName('cashback')
    } else {
      pageViewName = getAddDebitCardPageViewEventName('refinance')
    }

    appInsightsContext.trackEvent({
      name: pageViewName,
    })
  }, [appInsightsContext, reactivation, refi, cardTypesLoading])

  const iframeLoadingLimit =
    Number(getConfigValue('ADD_DEBIT_IFRAME_LOAD_LIMIT')) * 1000
  // Begin iframe loading and load time
  // Begin loadingLimit timer
  useEffect(() => {
    if (urlDetails?.url) {
      setIframeStartLoadTime(new Date().getTime())
      const timerId = setTimeout(() => {
        setLoadingLimitReached(true)
      }, iframeLoadingLimit)
      setTimer(timerId)
    }
  }, [iframeLoadingLimit, urlDetails, cardTypesLoading])

  // Iframe is done loading and loading time
  useEffect(() => {
    if (iframeHasLoaded) {
      const timeItTookIframeToLoadInMilliseconds = Math.abs(
        iframeFinishedLoadingTime - iframeStartLoadTime,
      )
      appInsightsContext.trackEvent(
        {name: AnalyticsEventNames.IFRAME_LOADING_DONE},
        {duration: timeItTookIframeToLoadInMilliseconds},
      )
    }
  }, [
    appInsightsContext,
    iframeFinishedLoadingTime,
    iframeHasLoaded,
    iframeStartLoadTime,
    cardTypesLoading,
  ])

  // Iframe is still loading past 60 seconds, show toast message and refetch
  useEffect(() => {
    if (
      loadingLimitReached &&
      retryAttempts < 2 &&
      timer &&
      !iframeHasLoaded
    ) {
      appInsightsContext.trackEvent({
        name: AnalyticsEventNames.IFRAME_LOADING_WARNING,
      })
      pushToast({
        title: 'Oops!',
        message: `We're sorry, this is taking longer than expected. This page will automatically reload.`,
        variant: 'danger',
      })
      setRefetchAttempt(true)
      refetch()
      setRetryAttempts(attempts => attempts + 1)
    }
  }, [
    appInsightsContext,
    iframeHasLoaded,
    loadingLimitReached,
    pushToast,
    refetch,
    retryAttempts,
    timer,
  ])

  // If iframe was refetch increment retry attempts
  useEffect(() => {
    if (loadingLimitReached && timer && retryAttempts < 2) {
      clearTimeout(timer)
      setLoadingLimitReached(false)
    }
  }, [loadingLimitReached, refetchAttempt, retryAttempts, timer])

  // Iframe is still loading past 60 seconds and we refetch twice already
  useEffect(() => {
    if (loadingLimitReached && retryAttempts >= 2 && !iframeHasLoaded) {
      pushToast({
        title: 'An error has occurred',
        message: `Please try again later.`,
        variant: 'danger',
      })
      setLoadingLimitReached(false)
    }
  }, [iframeHasLoaded, loadingLimitReached, pushToast, retryAttempts])

  // If user tries to "refresh" or load the page while on /add-card-and-pay, redirect to the home screen
  if (history.location.state === undefined) {
    return <Redirect to="/home" />
  }

  if (!urlDetails?.url && cardTypesLoading) {
    return <PageSpinner />
  }
  return (
    <Container>
      {urlDetails?.url && !cardTypesLoading && (
        <>
          <Card className="full-page mb-3">
            <Card.Header style={{textAlign: 'center'}}>
              <div className={`mt-3 ${styles.title}`}>
                <h2>Add Card</h2>
                {showError && (
                  <p>
                    <span className="text-danger">
                      Error processing card. Please try again.
                    </span>
                  </p>
                )}
              </div>
            </Card.Header>
            <Card.Body className="mt-1">
              {showDisbursementError ? (
                <div className={styles.disbursement}>
                  <RiCloseCircleLine
                    className="text-danger"
                    color="#dc3545"
                    height={100}
                    width={100}
                  />
                  <p>Sorry!</p>
                  <span>
                    The card that you added is not eligible for disbursement
                  </span>
                  <div className={styles.disbursement__buttons}>
                    <Button onClick={goBack}>Back</Button>
                    <Button onClick={handleAddNewCard}>Add New Card</Button>
                  </div>
                </div>
              ) : (
                <iframe
                  className={`border-0 w-100 fs-exclude ${
                    isSuccessfullyAdd
                      ? styles.fullHeightCompleted
                      : styles.fullHeight
                  } `}
                  data-ref="iframe"
                  id="iframe"
                  sandbox="allow-forms allow-scripts allow-same-origin allow-popups"
                  scrolling="yes"
                  src={isLoadingUrl ? undefined : urlDetails.url}
                  title="Add Debit Card"
                  onLoad={iframeHasLoadedCallback}
                />
              )}
            </Card.Body>
            <Card.Footer>
              <Row className="mt-3 justify-content-md-start align-items-center">
                <Col className="col-6 col-md-3 order-1 order-sm-1 p-3 p-md-0 text-center text-md-left">
                  <Button
                    className="pl-0 text-dark"
                    data-ref="back-button"
                    variant="link"
                    onClick={goBack}
                  >
                    <MdChevronLeft width=".75em" />
                    <Content type={ContentSlot.REPAY_BACKBUTTON} />
                  </Button>
                </Col>
                {isSuccessfullyAdd && !showError && (
                  <Col className="col-6 col-sm-2 p-0 order-2 order-sm-2 offset-md-2">
                    <Button
                      block
                      color="primary"
                      data-ref="submit"
                      size="sm"
                      type="submit"
                      onClick={goBack}
                    >
                      Done
                    </Button>
                  </Col>
                )}
              </Row>
            </Card.Footer>
          </Card>
        </>
      )}
    </Container>
  )

  /**
   * Effects
   */

  /**
   * Handles payment response and submits form if valid
   * @return function to unset the event listener
   */
  function handleIframeMessage() {
    const messageEventHandler = (e: MessageEvent) => {
      // eslint-disable-next-line no-console
      console.log('Event', e)
      const {data}: {data: DebitCardData | undefined} = e

      if (
        !data?.paymentData ||
        !data.paymentResponseData.saved_payment_method.token
      ) {
        return
      }
      if (
        isDisbursmentCard &&
        !data.paymentResponseData.saved_payment_method
          .is_eligible_for_disbursement
      ) {
        setShowError(false)
        setShowDisbursementError(true)
        return
      }
      const year = new Date().getFullYear().toString().slice(0, 2)
      const dateExpiry = data.paymentData.card_expiration
      const yearExpiry = dateExpiry.slice(2, 4)
      const monthExpiry = dateExpiry.slice(0, 2)

      const dateExpiryFormatted = `${monthExpiry}/${year}${yearExpiry}`
      const values: CreateCreditCardFormValues = {
        cardReference: data.paymentResponseData.saved_payment_method.token,
        cardNumber: data.paymentData.card_last_four,
        nameOnCard: data.paymentResponseData.name_on_card,
        cardDateExpiry: dateExpiryFormatted,
        cardType: data.paymentResponseData.payment_type_id,
        isEligibleForDisbursement:
          data.paymentResponseData.saved_payment_method
            .is_eligible_for_disbursement,
      }

      addCard({values, cardTypes, isOneTimePaymentCard: false})

      setPaymentData({
        ...paymentData,
        cardToken: parseInt(
          data.paymentResponseData.saved_payment_method.token,
        ),
        cardTokenLast4: data.paymentData.card_last_four,
        cardType: data.paymentResponseData.payment_type_id,
      })

      // Track the successful addition of the debit card
      appInsightsContext.trackEvent(
        {name: AnalyticsEventNames.ADD_DEBIT_CARD},
        {cardLastFour: data.paymentData.card_last_four},
      )
    }
    window.addEventListener('message', messageEventHandler)

    return () => {
      window.removeEventListener('message', messageEventHandler)
    }
  }

  /**
   * Callbacks
   */

  /**
   * Navigates to prev screen
   */
  function goBackCallback() {
    setIsSuccessfullyAdd(false)
    const targetPathname = history.location.state?.from ?? '/account'
    const targetHash =
      history.location.state?.from === '/account'
        ? '#paymentMethod'
        : undefined
    history.push({pathname: targetPathname, hash: targetHash})
  }

  /**
   * Iframe has loaded callback
   */
  function iframeHasLoadedCallback() {
    setIframeHasLoaded(true)
    clearAllToasts()
    const loadTime = new Date().getTime()
    setIframeFinishedLoadingTime(loadTime)
  }
}

/**
 * Add one time debit card component
 */
export default AddDebitCard
