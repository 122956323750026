import {
  CustomerSearchRequest,
  CustomerSearchResponse,
  RemoteAddressResponse,
} from 'models/Auth'
import {AuthActionsType, AuthActionTypes} from '../useAuthReducer'
import React from 'react'
import {vergentService} from 'utils/vergent-service'
import {StandardError, UnknownError, VergentError} from 'models/Errors'
import {LOCKED_ACCOUNT_SEARCH_MESSAGE_KEY} from '../handle-lock-search-customer'
import Axios from 'axios'

interface SearchCustomerAccountArgs {
  customerSearchRequestBody: CustomerSearchRequest
  dispatchAuthState: React.Dispatch<AuthActionsType>
}

/**
 * Search customer error types
 */
export enum SearchCustomerErrorTypes {
  NoLoanFound = 'NO_LOAN_FOUND',
  LockedAccountSearch = 'LOCKED_ACCOUNT_SEARCH',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  AccountSearchLocked = 'ACCOUNT_SEARCH_LOCKED',
  UnknownErrorOccured = 'UNKNOWN_ERROR',
}

/**
 * Find account action
 */
export const searchCustomerAccountAction = async ({
  customerSearchRequestBody,
  dispatchAuthState,
}: SearchCustomerAccountArgs) => {
  const dateParts = customerSearchRequestBody.birthDate.split('/')

  const formattedCustomerSearchRequestBody: CustomerSearchRequest = {
    ...customerSearchRequestBody,
    idNumber: customerSearchRequestBody.idNumber.split('-').join(''),
    birthDate: `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`,
    phoneNumber: customerSearchRequestBody.phoneNumber.replace(/[^0-9]/g, ''),
  }

  try {
    const {
      data: {ip},
    } = await Axios.get<RemoteAddressResponse>(
      'https://api.ipify.org/?format=json',
    )

    const {
      data: {customerSearchResults},
    } = await vergentService.post<CustomerSearchResponse>(
      '/VergentCustomer/Customer/Search',
      {...formattedCustomerSearchRequestBody, remoteAddress: ip},
    )

    dispatchAuthState({
      type: AuthActionTypes.AccountFound,
      payload: {
        personReferenceNumber: String(customerSearchResults[0].customerId),
      },
    })
  } catch (error) {
    const {
      errorWasHandled,
      errorInstance,
      status,
      additionalProp1,
    } = error as VergentError

    if (errorWasHandled) throw new StandardError({})

    if (errorInstance?.message === LOCKED_ACCOUNT_SEARCH_MESSAGE_KEY) {
      throw new StandardError({
        type: SearchCustomerErrorTypes.LockedAccountSearch,
        errorInstance,
      })
    }

    if (status === 404) {
      throw new StandardError({
        type: SearchCustomerErrorTypes.NoLoanFound,
        errorInstance: new Error('Unable to find loan.'),
        auxiliarNumberProp: Number(additionalProp1),
      })
    }

    if (errorInstance) {
      throw new StandardError({
        type: SearchCustomerErrorTypes.InternalServerError,
        errorInstance,
      })
    }

    throw new UnknownError({
      type: SearchCustomerErrorTypes.UnknownErrorOccured,
      errorInstance,
    })
  }
}
