import React, {useEffect} from 'react'

import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js'
import {Button, Card} from 'react-bootstrap'
import {useLocation} from 'react-router-dom'

import {base64ToArrayBuffer} from 'utils/forms/helpers'
import {AnalyticsPageNames} from 'models/Analytics'
import {useLoanData} from 'utils/hooks/useLoanData'

interface LocationState {
  declineDocument: [{data: string}, {data: string}]
}

const ContactUs: React.FC = () => {
  /**
   * Analytics & Tracking
   */
  const appInsightsContext = useAppInsightsContext()
  const trackMetric = useTrackMetric(
    appInsightsContext,
    AnalyticsPageNames.CONTACT_US,
  )
  useEffect(() => {
    trackMetric()
  }, [trackMetric])

  const location = useLocation()
  const {declineDocument} = location.state as LocationState
  const {customer: userdata} = useLoanData()

  /**
   * Create url from base64 string
   * @param base64 Base64 string for the pdf document
   * @return ulr
   */
  function createUrlFromBase64String(base64: string) {
    const file = new Blob([base64ToArrayBuffer(base64)], {
      type: 'application/pdf',
    })
    return URL.createObjectURL(file)
  }

  const cashstoreContactUsLink = 'https://www.cashstore.com/contact-us'

  let link
  if (userdata?.customerAddress.state === 'TX') {
    link = (
      <>
        <a
          href={createUrlFromBase64String(declineDocument[1].data)}
          rel="noopener noreferrer"
          target="_blank"
        >
          Decline Letter
        </a>
        <br />
        <a
          href={createUrlFromBase64String(declineDocument[0].data)}
          rel="noopener noreferrer"
          target="_blank"
        >
          Treemac Decline Letter
        </a>
      </>
    )
  } else {
    link = (
      <a
        href={createUrlFromBase64String(declineDocument[0].data)}
        rel="noopener noreferrer"
        target="_blank"
      >
        Decline Letter
      </a>
    )
  }

  return (
    <Card className="mb-2">
      <Card.Header className="text-center">
        <h1>Please Give Us a Call</h1>
      </Card.Header>
      <Card.Body className="text-center">
        <p>
          We ran into a problem verifying your military status. Please see
          below for more information and call your local store at
          1-888-950-2274 for additional assistance.
        </p>
        {link}
      </Card.Body>
      <Card.Footer className="text-center">
        <Button block={false} href={cashstoreContactUsLink}>
          Contact us
        </Button>
      </Card.Footer>
    </Card>
  )
}

/**
 * Contact us page with decline letter
 */
export default ContactUs
