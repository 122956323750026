import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useContext,
} from 'react'

import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js'
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  Form as BootstrapForm,
} from 'react-bootstrap'
import {Redirect, useHistory} from 'react-router-dom'
import {GoChevronLeft, GoChevronRight} from 'react-icons/go'
import * as Yup from 'yup'
import {Form, Formik, FormikProps, useFormikContext} from 'formik'
import FormikErrorFocus from 'formik-error-focus'
import {useMutation} from 'react-query'

import {getLoanScheduleRequest} from 'utils/edge'
import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import NumberStepper from 'components/NumberStepper'
import {RefinanceSteps} from 'components/NumberStepper/Config'
import PageSpinner from 'components/PageSpinner'
import LoanSchedule from 'components/LoanSchedule'
import {useLoanData} from 'utils/hooks/useLoanData'
import {useRefinanceData} from 'utils/hooks/useRefinanceData'
import {
  RefinanceFormValues,
  PaymentMethodTypes,
  GetLoanScheduleValues,
  ConfirmRefinanceLoanValues,
} from 'models/Edge'
import {formatCurrency, formatCurrencyToFloat} from 'utils/data-formatting'
import DebitCardsDropdown from 'components/Form/DebitCardsDropdown'
import {
  setRefinanceData,
  setPartialPaymentData,
  setPartialRefinancePaymentData,
  getRefinanceData,
} from 'utils/cache'
import {
  dateSortAsc,
  getFormatedFullDate,
  largeScreenFormattedDate,
  mobileScreenFormattedDate,
} from 'utils/dates'
import {Breakpoints} from 'models/Breakpoints'
import {AnalyticsPageNames, AnalyticsEventNames} from 'models/Analytics'
import {ToastContext} from 'components/Toast'
import {useAuth} from 'auth'
import DebitCardsDropdownAutopayment from 'components/Form/DebitCardsDropdownAutopayment'
import {OfferSelector} from 'components/Form/OfferSelector'

const RefinancePayment: React.FC<{
  showNumberStepper?: boolean
}> = ({showNumberStepper = true}) => {
  /**
   * Analytics & Tracking
   */
  const appInsightsContext = useAppInsightsContext()
  const trackMetric = useTrackMetric(
    appInsightsContext,
    AnalyticsPageNames.REFINANCE,
  )
  useEffect(() => {
    trackMetric()
  }, [trackMetric])

  useEffect(() => {
    appInsightsContext.trackEvent({
      name: AnalyticsEventNames.PRINCIPAL_REFINANCE_VISIT,
    })
  }, [appInsightsContext])

  /**
   * Hooks
   */
  const {pushToast} = useContext(ToastContext)
  const history = useHistory()
  const {
    currentLoan,
    customer,
    fullName,
    refinanceApprovalDetails,
    status: loanStatus,
    isLoading: isLoanDataLoading,
  } = useLoanData()
  const {
    refinanceApproved,
    refiOptions,
    status: cashbackOptStatus,
  } = useRefinanceData()
  const [loanSchedulePayload, setLoanSchedulePayload] = useState<
    GetLoanScheduleValues
  >()
  const {user} = useAuth()

  /**
   * Handles First Payment Date option value based on screen size
   */
  const [width, setWidth] = React.useState(window.innerWidth)
  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])
  /**
   * Callbacks, mutations
   */
  const goBack = useCallback(goBackCallback, [history])
  const submitFormValues = useCallback(submitFormValuesCallback, [refiOptions])
  const {
    mutate: requestLoanSchedule,
    isLoading: loanScheduleLoading,
    isSuccess: loanScheduleSuccess,
    data: loanScheduleData,
    error: loanScheduleError,
  } = useMutation(getLoanScheduleRequest)

  /**
   * Variables
   */
  const refinanceOption = refiOptions?.refinance

  const minPaymentAmount = `${formatCurrency(
    refinanceOption?.requiredToPay ?? 0,
  )}`

  /**
   * Memos
   */
  const refi = useMemo(getRefinanceData, [])
  const initialFormValues = useMemo(
    () =>
      ({
        paymentAmount:
          refi?.refinance?.paymentAmount?.toString() ?? minPaymentAmount,
        paymentMethod: PaymentMethodTypes.NULL,
        autoPaymentMethod: PaymentMethodTypes.NULL,
        autoCvv: '',
        cvv: '',
        firstPaymentDate:
          refinanceApprovalDetails?.suggestedFirstPaymentDates.length === 1
            ? refinanceApprovalDetails.suggestedFirstPaymentDates[0]
            : '',
      } as RefinanceFormValues),
    [minPaymentAmount, refi, refinanceApprovalDetails],
  )

  const FormSchema = Yup.object().shape({
    paymentMethod: Yup.string().required('Payment method is required'),
    autoPaymentMethod: Yup.string().required(
      'Automatic Payment method is required',
    ),
    cvv: Yup.string().when('paymentMethod', (paymentMethod: string) => {
      const matchExistingPaymentMethod = /^(.+?):/
      const isExistingCard = matchExistingPaymentMethod.exec(paymentMethod)

      if (isExistingCard) {
        return Yup.string()
          .required('CVV is required')
          .test(
            'three-digit-number',
            'CVV number must be 3 digits long',
            (value: string) => {
              if (value === undefined) return true
              return value.replace(/\D/g, '').length === 3
            },
          )
      }
    }),
    firstPaymentDate: Yup.string().required('First payment date is required'),
  })

  return (
    <main>
      {(loanStatus === 'loading' || cashbackOptStatus === 'loading') && (
        <PageSpinner />
      )}
      {/** Redirect to home if user is not eligble for refinance */}
      {!refinanceApproved && <Redirect to="/home" />}
      <Container>
        {showNumberStepper && (
          <NumberStepper activeStep={2} steps={RefinanceSteps} />
        )}
        <section>
          <Card className="mb-3 full-page">
            <Card.Header className="allow-full-width">
              <h1>
                <Content type={ContentSlot.REFINANCE_TITLE} />
              </h1>
              <h5>
                <Content type={ContentSlot.REFINANCE_DESCRIPTION} />
              </h5>
              <h5>
                <Content type={ContentSlot.REFINANCE_SECONDARYDESCRIPTION} />
              </h5>
            </Card.Header>
            <Formik
              initialValues={initialFormValues}
              validationSchema={FormSchema}
              onSubmit={submitFormValues}
            >
              {({
                values,
                handleBlur,
                handleChange,
                setFieldValue,
                touched,
                errors,
              }: FormikProps<RefinanceFormValues>) => {
                const handleSelectOffer = (offerId: number) => {
                  setFieldValue('offerId', offerId)
                }
                return (
                  <Form>
                    <SideEffect />
                    <OfferSelector
                      disabled
                      offers={
                        customer?.refinanceModelOption?.map(
                          refiModelOption => ({
                            id: refiModelOption.loanModelId,
                            name: refiModelOption.loanModelName,
                            range: {
                              max: refiModelOption.maxAmount,
                              min: refiModelOption.minAmount,
                            },
                          }),
                        ) ?? []
                      }
                      selectedOfferId={
                        customer?.refinanceModelOption[0].loanModelId ?? 0
                      }
                      setSelectedOfferId={handleSelectOffer}
                    />
                    <Card.Body>
                      {/* Debit Card for automatic payments */}
                      <Row>
                        <Col lg="6" xs="12">
                          <DebitCardsDropdownAutopayment
                            showAddCard
                            cardInfo={customer?.customerCards}
                            errors={errors}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            paymentMethod={values.autoPaymentMethod}
                            showAddCardAndPay={false}
                            showOneTimeCard={false}
                            touched={touched}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12">
                          <p>
                            *If you do not wish to enroll in automatic
                            payments, please visit one of our store locations
                            to complete your loan origination.
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6" xs="12">
                          <BootstrapForm.Group controlId="firstPaymentDate">
                            <BootstrapForm.Label>
                              First Payment Date
                            </BootstrapForm.Label>
                            <BootstrapForm.Control
                              as="select"
                              data-ref="first-payment-date"
                              name="firstPaymentDate"
                              value={values.firstPaymentDate}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            >
                              <option key="default" disabled value="">
                                Select a Date
                              </option>
                              {refinanceApprovalDetails?.suggestedFirstPaymentDates
                                .sort(dateSortAsc)
                                .map((date, index) =>
                                  width > Breakpoints.mobile ? (
                                    <option key={index} value={date}>
                                      {largeScreenFormattedDate(date)}
                                    </option>
                                  ) : (
                                    <option key={index} value={date}>
                                      {mobileScreenFormattedDate(date)}
                                    </option>
                                  ),
                                )}
                            </BootstrapForm.Control>

                            {touched.firstPaymentDate &&
                              errors.firstPaymentDate && (
                                <BootstrapForm.Text className="text-danger">
                                  {errors.firstPaymentDate}
                                </BootstrapForm.Text>
                              )}
                          </BootstrapForm.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <div className="w-100 mb-2">
                            <LoanSchedule
                              error={
                                loanScheduleError as Error | null | undefined
                              }
                              isLoading={
                                loanScheduleLoading || isLoanDataLoading
                              }
                              isSuccess={loanScheduleSuccess}
                              loanSchedule={loanScheduleData?.loanSchedule}
                              startDate={new Date()}
                              tilaDisclosure={
                                loanScheduleData?.tilaDisclosureDetailsDto
                              }
                              timePeriod={
                                refinanceApprovalDetails?.periodLengthField
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6" xs="12">
                          <p className="font-weight-bold mb-3">
                            Your minimum payment today is {minPaymentAmount}.
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6" xs="12">
                          <DebitCardsDropdown
                            showAddCardAndPay
                            cardInfo={customer?.customerCards}
                            cvv={values.cvv}
                            errors={errors}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            paymentMethod={values.paymentMethod}
                            touched={touched}
                          />
                        </Col>
                      </Row>
                    </Card.Body>

                    <Card.Footer>
                      <Container>
                        <Row className="justify-content-between">
                          <Col className="col-12 col-md-6 order-2 order-md-1 p-3 p-md-0 text-center text-md-left">
                            <Button
                              className="pl-0 text-dark"
                              variant="link"
                              onClick={goBack}
                            >
                              <GoChevronLeft />{' '}
                              <Content
                                type={ContentSlot.CASHBACK_BACKBUTTON}
                              />
                            </Button>
                          </Col>
                          <Col className="col-12 col-md-6 p-0 order-1 order-md-2 text-right">
                            <Button data-ref="submit" type="submit">
                              <Content
                                type={ContentSlot.CASHBACK_NEXTBUTTON}
                              />{' '}
                              <GoChevronRight />
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                    </Card.Footer>
                    <FormikErrorFocus
                      align="middle"
                      duration={500}
                      ease="linear"
                      focusDelay={200}
                    />
                  </Form>
                )
              }}
            </Formik>
          </Card>
        </section>
      </Container>
    </main>
  )

  /**
   * Navigates to prev screen
   */
  function goBackCallback() {
    history.goBack()
  }

  /**
   * Auto request loan schedule if user inupts complete request payload
   * @return null
   */
  function SideEffect() {
    const {values, errors} = useFormikContext<RefinanceFormValues>()

    useEffect(() => {
      if (
        errors.firstPaymentDate?.length ||
        errors.paymentAmount?.length ||
        !refinanceApprovalDetails ||
        !currentLoan
      )
        return

      const paymentAmount = formatCurrencyToFloat(values.paymentAmount)
      const loanAmount = currentLoan.todaysPayoffAmount - paymentAmount
      const {firstPaymentDate} = values

      if (
        isNaN(paymentAmount) ||
        isNaN(loanAmount) ||
        !values.firstPaymentDate.length
      )
        return

      if (
        loanAmount === loanSchedulePayload?.loanAmount &&
        firstPaymentDate === loanSchedulePayload.firstPaymentDate
      )
        return

      const payload: GetLoanScheduleValues = {
        firstPaymentDate,
        loanAmount,
        numberOfPeriods: refinanceApprovalDetails.numberOfPeriods,
        loanId: currentLoan.loanId,
        amountRequested: loanAmount,
        apr: currentLoan.originalFeeApr,
        currentBalance: currentLoan.currentBalance,
        feeBalance: currentLoan.feeBalance,
      }
      const todaysDate = getFormatedFullDate(new Date())

      let bankId = 0
      if (customer && customer.customerBankAccounts.length > 0) {
        bankId = customer.customerBankAccounts[0].id
      }

      let refiCardId = 0
      if (customer && customer.customerCards.length > 0) {
        refiCardId = customer.customerCards[0].id
      }

      const valuesToLoanEstimate: ConfirmRefinanceLoanValues = {
        customerId: Number(user?.personReferenceNumber),
        companyId: customer?.companyId ?? 0,
        storeId: customer?.refinanceModelOption[0].storeId ?? 0,
        loanModelId: customer?.refinanceModelOption[0].loanModelId ?? 0,
        cosignerId: 0,
        originationDate: todaysDate,
        fundingDate: todaysDate,
        loanAmount,
        dueDate: firstPaymentDate,
        numberOfPayments: 0,
        bankId,
        fundingMethod: 'CARD',
        fundingLocation: 'NotSpecified',
        workflowId: 0,
        autopayEnrollment: {
          enrollAutopay: true,
          autopayType: 'ACH',
          autopayCardId: 0,
          autopayBankId: bankId,
        },
        initialLoanStatus: 'None',
        initialDrawAmount: 0,
        refinanceData: [
          {
            headerId: currentLoan.loanId,
            loanModelId: currentLoan.loanModelId,
            storeId: Number(currentLoan.storeId),
            payment: {
              amount: paymentAmount,
              paymentDate: todaysDate,
              paymentMethod: 'CARD',
              bankId,
              cardId: refiCardId,
            },
          },
        ],
      }

      requestLoanSchedule({valuesToLoanEstimate, payload})
      setLoanSchedulePayload(payload)
    }, [errors.firstPaymentDate, errors.paymentAmount, values])

    return null
  }

  /**
   * Submit form values
   * @param values form values
   * @return url redirect
   */
  function submitFormValuesCallback(values: RefinanceFormValues) {
    const {cvv} = values
    const paymentAmount = formatCurrencyToFloat(values.paymentAmount)
    const {firstPaymentDate} = values

    const currentPaymentCard = customer?.customerCards?.find(
      card => card.id === Number(values.paymentMethod.replace(/[^0-9.]/g, '')),
    )

    const AutomaticPaymentCard = customer?.customerCards?.find(
      card =>
        card.id === Number(values.autoPaymentMethod.replace(/[^0-9.]/g, '')),
    )

    const autoPaymentCard = {
      autoCardType: AutomaticPaymentCard?.cardType ?? '',
      autoCardToken: AutomaticPaymentCard?.cardTypeId ?? 0,
      autoCardTokenLast4: AutomaticPaymentCard?.accountNumberMasked ?? '',
      autoCardIsFundable: AutomaticPaymentCard?.isExpired,
    }

    const Paymentcard = {
      cardCvv: cvv,
      cardType: currentPaymentCard?.cardType ?? '',
      cardToken: currentPaymentCard?.cardTypeId ?? 0,
      cardTokenLast4: currentPaymentCard?.accountNumberMasked ?? '',
      cardIsFundable: currentPaymentCard?.isExpired,
    }
    const formatedPaymentMethods = values.paymentMethod.replace(/:.*$/, '')
    if (!currentLoan?.refinanceApprovalDetails?.paymentFrequencyId) {
      pushToast({
        title: 'Insufficient information to continue, please contact support',
        variant: 'danger',
      })
      return history.push('/refinance-issue', {
        from: history.location.pathname,
      })
    }
    switch (formatedPaymentMethods) {
      case PaymentMethodTypes.EXISTING_CARD:
        setRefinanceData({
          ...Paymentcard,
          autoPaymentCard: {
            cardType: autoPaymentCard.autoCardType,
            cardToken: autoPaymentCard.autoCardToken,
            cardTokenLast4: autoPaymentCard.autoCardTokenLast4,
          },
          cardId: currentPaymentCard?.id,
          autoPaymentCardId: AutomaticPaymentCard?.id,
          paymentAmount,
          firstPaymentDate,
          customerName: fullName,
          storeId: currentLoan.refinanceApprovalDetails.refiStoreId.toString(),
          companyId: customer?.companyId,
          paymentFrequencyId:
            currentLoan.refinanceApprovalDetails.paymentFrequencyId,
          isRefi: true,
          loanModelId: currentLoan.refinanceApprovalDetails.refiLoanModelId,
        })

        return history.push('/confirm-origination', {
          from: history.location.pathname,
        })
      case PaymentMethodTypes.ADD_CARD_AND_PAY:
        setPartialRefinancePaymentData({
          firstPaymentDate,
          paymentFrequencyId:
            currentLoan.refinanceApprovalDetails.paymentFrequencyId,
          isRefi: true,
          companyId: customer?.companyId,
          storeId: currentLoan.refinanceApprovalDetails.refiStoreId.toString(),
          loanModelId: currentLoan.refinanceApprovalDetails.refiLoanModelId,
        })
        setPartialPaymentData(paymentAmount, fullName)
        return history.push('/add-card-and-pay', {
          from: history.location.pathname,
        })
      default:
    }
  }
}

/**
 * Cash Back Payment Page
 */
export default RefinancePayment
