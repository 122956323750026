import {useReducer} from 'react'
import type {AuthStateType, ActionMapType} from './auth.types'
import type {User} from 'models/Auth'

const initialAuthState: AuthStateType = {
  user: null,
  isLoading: true,
  isLoggedIn: false,
  isAccountLocked: false,
}

/**
 * Authentication action types
 */
export enum AuthActionTypes {
  Initialize = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  RefreshUser = 'REFRESH_USER',
  AccountFound = 'ACCOUNT_FOUND',
  LockAccount = 'LOCK_ACCOUNT',
  ResetRegisterFlow = 'RESET_REGISTER_FLOW',
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type AuthPayloadType = {
  [AuthActionTypes.Initialize]: {
    isLoggedIn: boolean
    user: User | null
  }
  [AuthActionTypes.Login]: {
    user: User | null
  }
  [AuthActionTypes.Logout]: undefined
  [AuthActionTypes.RefreshUser]: {
    user: Partial<User>
  }
  [AuthActionTypes.AccountFound]: {
    personReferenceNumber: string
  }
  [AuthActionTypes.LockAccount]: undefined
  [AuthActionTypes.ResetRegisterFlow]: undefined
}

/**
 * Authentication actions type
 */
export type AuthActionsType = ActionMapType<
  AuthPayloadType
>[keyof ActionMapType<AuthPayloadType>]

const AuthReducer = (
  state: AuthStateType,
  action: AuthActionsType,
): AuthStateType => {
  switch (action.type) {
    case AuthActionTypes.Initialize:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: action.payload.isLoggedIn,
        user: action.payload.user,
      }
    case AuthActionTypes.Login:
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload.user,
      }
    case AuthActionTypes.Logout:
      return {
        ...initialAuthState,
        isLoading: false,
      }
    case AuthActionTypes.RefreshUser:
      return {
        ...state,
        user: {
          ...state.user,
          ...(action.payload.user as User),
        },
      }
    case AuthActionTypes.AccountFound:
      return {
        ...state,
        user: {
          ...(state.user as User),
          personReferenceNumber: action.payload.personReferenceNumber,
        },
      }
    case AuthActionTypes.LockAccount:
      return {
        ...state,
        isAccountLocked: true,
      }
    case AuthActionTypes.ResetRegisterFlow:
      return {
        ...state,
        user: null,
      }
    default:
      return state
  }
}

/**
 * Reducer to handle authentication state
 * @return auth state and auth state dispatch
 */
export const useAuthReducer = () => {
  const [authState, dispatchAuthState] = useReducer(
    AuthReducer,
    initialAuthState,
  )

  return {authState, dispatchAuthState}
}
