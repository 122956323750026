import axios from 'axios'
import {
  VergentUpdateContatctInfoValues,
  CreateCreditCardFormValues,
} from 'models/AccountFormValues'
import {
  DebitCardUrl,
  PostPaymentValues,
  DeletePaymentMehodsValues,
  PostPaymentResponse,
  GetLoanScheduleValues,
  RefinanceLoanValues,
  OriginateLoanValues,
  RefinanceLoanResponse,
  OriginateLoanResponse,
  LoanScheduleResponse,
  CashWiseInformationRequest,
  CashWiseInformationResponse,
  HealthCheckResponse,
  LoanTransactionResponse,
  LoansModelAmountsResponse,
  GetRefinanceEstimateResponse,
  PaymentSuccessDetail,
  DocumentResponse,
  RefiPaymentReceipt,
  TransactionModel,
  CustomerDetailsResponse,
  DownloadDocuments,
  ConfirmRefinanceLoanValues,
  ConfirmRefinanceCashbackLoanValues,
  ReactivationCreateBody,
  PostReactivationCreateResponse,
  ReactivationSyncResponse,
  DetailsResponse,
  GetPaymentAmountRequiredToRefinanceResponse,
  ReactivationPostApplicationDetails,
  ReactivationInitialConfirmationResponse,
  OfferDataResponse,
  AmountsResponse,
  RequestPinByTextResponse,
  RequestPinByTextBody,
  GetReactivationPaymentScheduleResponse,
  GetReactivationPaymentScheduleArgs,
  PaymentSchedule,
  ReactivationSubmitResponse,
  ReactivationSubmitPayload,
  CustomerCard,
  GetSigningStatus,
  VerifyPinRequest,
  VerifyPinResponse,
  MyAccountDownloadDocuments,
  FullProcessInfoModel,
  CustomerLoanDetailResponse,
  GetFullLoansQueryParams,
} from 'models/Edge'
import {getConfigValue} from 'utils/environment'
import {CashStoreLocation} from 'models/CashStoreLocation'
import {isIE11} from './browser-detection'
import {customerPortalService, vergentService} from './vergent-service'
import {buildCustomerDetails, buildLoanDetails} from 'utils/build-loan-details'
import {CreditCardRequest} from 'models/PaymentMethods'
import {LocalStorageKeys} from './common'
import {getTypeCreditCard} from './payment-methods'
import {queryClient} from 'client'
import {getFormatedFullDate} from './dates'
import {OfferData, setReactivationOffers} from './cache'

const edgeUrl = getConfigValue('EDGE_SERVICE_URL')
const serviceCallDelay = Number(getConfigValue('SERVICE_CALL_DELAY'))
const customerDetailsEndpoint = '/VergentCustomer/Details'

/**
 * Takes a personReferenceNumber and returns current loan details
 * @param _key unique identifier for useQuery
 * @param _personReferenceNumber the personReferenceNumber associated with a loan
 * @param userName username
 * @param preserveOneTimePaymentCard boolean
 * @return currentLoanDetails
 * TODO: request the information from the API and transform the data
 */
export async function getLoanDetails(
  _key?: string,
  _personReferenceNumber?: string,
  userName?: string | null,
  preserveOneTimePaymentCard?: boolean,
) {
  //Initial variables
  let customerLoanDetail = null
  let customerLoanData = null
  let transactionHistoy: TransactionModel[] | undefined = []
  let userData = null
  let regularRefinanceEstimate: GetRefinanceEstimateResponse | null = null
  let cashbackRefinanceEstimate: GetRefinanceEstimateResponse | null = null

  let maximumApprovalAmount = Number(
    getConfigValue('DEFAULT_MAX_APPROVAL_AMOUNT'),
  )
  let minimumApprovalAmount = 0

  try {
    const {data: customerDetails} = await vergentService.get<DetailsResponse>(
      customerDetailsEndpoint,
      {
        params: {
          userName: userName ?? '',
          maxNumberOfLoanRecords: 1,
        },
      },
    )
    if (!preserveOneTimePaymentCard && customerDetails.customerProfile) {
      await deleteOneTimePaymentMethods({
        customerId: customerDetails.customerProfile.customerId,
        customerCards: customerDetails.customerProfile.cards,
      })
    }
    const [loanData] = customerDetails.customerLoans.fullProcessInfoModels
    customerLoanDetail = customerDetails.customerLoans
    customerLoanData = loanData
    userData = customerDetails.customerProfile
  } catch (error) {
    console.error(error)
    logErrorToApplicationInsights((error as Error).message)
  }
  try {
    if (customerLoanData) {
      const {
        data: {loanTransactionHistoryList} = {},
      } = await vergentService.get<LoanTransactionResponse>(
        `/VergentLoans/Loans/${customerLoanData.loanHeaderId}/Transactions`,
      )
      transactionHistoy = loanTransactionHistoryList
    }
  } catch (error) {
    console.error(error)
    logErrorToApplicationInsights((error as Error).message)
  }

  try {
    if (customerLoanData && userData) {
      if (userData.refinanceLoanModelOptions.length > 0) {
        minimumApprovalAmount = userData.refinanceLoanModelOptions[0].minAmount
      } else {
        minimumApprovalAmount = 50
      }

      if (userData.maxInstLoanAmount > 0) {
        maximumApprovalAmount =
          userData.refinanceLoanModelOptions.length > 0
            ? Math.min(
                userData.maxInstLoanAmount,
                userData.refinanceLoanModelOptions[0].maxAmount,
              )
            : userData.maxInstLoanAmount
      } else if (userData.refinanceLoanModelOptions.length > 0) {
        maximumApprovalAmount = userData.refinanceLoanModelOptions[0].maxAmount
      } else {
        const {data: loansModelAmounts} = await vergentService.get<
          LoansModelAmountsResponse
        >(
          `/VergentLoans/LoansModelAmounts?loanModelId=${String(
            customerLoanData.loanModelId,
          )}&custId=${userData.customerId}`,
        )
        maximumApprovalAmount = Number(
          loansModelAmounts.minMaxRanges[0]?.maximum,
        )
        minimumApprovalAmount = Number(
          loansModelAmounts.minMaxRanges[0]?.minimum,
        )
      }
    }
  } catch (error) {
    console.error(error)
    logErrorToApplicationInsights((error as Error).message)
  }

  try {
    if (customerLoanData?.isEligibleForRefinance) {
      const {data: paymentAmountData} = await vergentService.get<
        GetPaymentAmountRequiredToRefinanceResponse
      >(`/VergentLoans/GetPaymentAmountRequiredToRefinance`, {
        params: {
          LoanId: customerLoanData.loanHeaderId,
          AmountRequested: minimumApprovalAmount,
        },
      })
      const todaysDate = getFormatedFullDate(new Date())
      const valuesToLoanEstimate: ConfirmRefinanceLoanValues = {
        customerId: Number(userData?.customerId),
        companyId: userData?.companyId ?? 0,
        loanModelId: userData?.refinanceLoanModelOptions[0].loanModelId ?? 0,
        storeId: Number(customerLoanData.storeId),
        cosignerId: 0,
        originationDate: todaysDate,
        fundingDate: todaysDate,
        loanAmount:
          customerLoanData.payoffAmount -
          paymentAmountData.paymentAmountRequiredToRefinance,
        dueDate: null,
        numberOfPayments: 0,
        bankId: userData?.banks[0].id ?? 0,
        fundingMethod: 'CARD',
        fundingLocation: 'NotSpecified',
        workflowId: 0,
        autopayEnrollment: {
          enrollAutopay: true,
          autopayType: 'ACH',
          autopayCardId: 0,
          autopayBankId: userData?.banks[0].id ?? 0,
        },
        initialLoanStatus: 'None',
        initialDrawAmount: 0,
      }
      const {data: LoanEstimate} = await vergentService.post<
        GetRefinanceEstimateResponse
      >(`/VergentLoans/Loans/LoanEstimate`, valuesToLoanEstimate)

      regularRefinanceEstimate = {
        paymentAmountRequiredToRefinance:
          paymentAmountData.paymentAmountRequiredToRefinance,
        paymentFrequency: customerLoanData.loanModelName.split(' ')[0],
        paymentSchedule: LoanEstimate.paymentSchedule,
      }
      cashbackRefinanceEstimate = {
        paymentAmountRequiredToRefinance:
          paymentAmountData.paymentAmountRequiredToRefinance,
        paymentFrequency: customerLoanData.loanModelName.split(' ')[0],
        paymentSchedule: LoanEstimate.paymentSchedule,
      }
    }
  } catch (error) {
    console.error(error)
    logErrorToApplicationInsights((error as Error).message)
  }

  const loanScheduleHistoryList =
    customerLoanDetail?.fullProcessInfoModels.find(
      loan => loan.loanStatus === 'Open',
    )?.paymentSchedule ??
    customerLoanDetail?.fullProcessInfoModels[0].paymentSchedule ??
    []

  return buildLoanDetails({
    customerLoanData,
    user: userData,
    loanTransactionHistoryList: transactionHistoy,
    loanScheduleHistoryList,
    regularRefinanceEstimate,
    cashbackRefinanceEstimate,
    maximumApprovalAmount,
  })
}

/**
 * Get customer profile information
 * @param personReferenceNumber customerr Id
 * @param userName userName form login
 * @return user information
 */
export async function getProfile(
  personReferenceNumber: string,
  userName: string | null,
) {
  try {
    const {
      data: {customerProfile: user},
    } = await vergentService.get<DetailsResponse>(customerDetailsEndpoint, {
      params: {
        userName: userName ?? '',
        maxNumberOfLoanRecords: 1,
      },
    })

    const loan = queryClient.getQueryData<CustomerDetailsResponse>([
      'loan',
      personReferenceNumber,
    ])

    if (loan) {
      const customerDetails = buildCustomerDetails(user)
      const loanUpdated: CustomerDetailsResponse = {
        ...loan,
        customerDetails: {
          ...loan.customerDetails,
          ...customerDetails,
        },
      }

      queryClient.setQueryData<CustomerDetailsResponse>(
        ['loan', personReferenceNumber],
        loanUpdated,
      )
    }
    return user
  } catch (error) {
    logErrorToApplicationInsights((error as Error).message)
    return null
  }
}

/**
 * Takes a store id and returns the details
 * @param storeNumber store number
 * @return store details
 * TODO: request the information from the API and remove mock data
 */
export async function getStoreById(storeNumber: string) {
  const {data} = await vergentService.get<CashStoreLocation>(
    `/VergentCompany/Stores/${storeNumber}`,
  )
  return data
}

/**
 * Takes a form submission and returns current loan details if they exist
 * @param values the information submitted to the form on FindLoan screen
 * @return currentLoanDetails if they exist
 */
export async function updateCustomerContact(
  values: VergentUpdateContatctInfoValues,
) {
  const {data} = await vergentService.post(
    '/VergentCustomer/Customer/ContactInformation',
    values,
  )

  return {values, data}
}

/**
 * Makes request to schedule payment for a loan
 * @param values the payment info submitted by customer
 * @return PostPaymentResponse
 */
export async function postPaymentRequest(values: PostPaymentValues) {
  const {data} = await vergentService.post<PostPaymentResponse>(
    `VergentPayment/Loans/Payments/CreditCardPayment`,
    {
      loanId: values.loanId,
      paymentId: values.cardId,
      oAuthToken: values.oAuthToken,
      amountDue: values.amountDue,
      isInRescindPeriod: values.isInRescindPeriod,
    },
  )

  if (data.success) {
    const detailObject = JSON.parse(data.detail) as PaymentSuccessDetail

    const {data: {loanTransactionHistoryList} = {}} = await vergentService.get<
      LoanTransactionResponse
    >(`/VergentLoans/Loans/${values.loanId}/Transactions`)

    return {
      ...data,
      paymentReceipt: loanTransactionHistoryList?.find(
        transaction =>
          transaction.transactionItemId === detailObject.transactionId,
      ),
    }
  }

  return data
}

/**
 * Makes request to disable a payment method
 * @param values payment method info
 * @return DeletePaymentMethodResponse
 */
export async function deletePaymentMethod(values: DeletePaymentMehodsValues) {
  const {data} = await vergentService.put(
    `/VergentLMS/DisableCustomerCard/${String(
      values.paymentMethodId,
    )}/${String(values.customerId)}`,
    values,
  )
  return data
}

interface DeleteOneTimePaymentMethodsArgs {
  customerCards: CustomerCard[]
  customerId: number
}

/**
 * Makes request to disable one time payment methods
 * @param values payload
 * @return DeletePaymentMethodResponse
 */
export async function deleteOneTimePaymentMethods(
  values?: DeleteOneTimePaymentMethodsArgs,
) {
  let oneTimePaymentCards = values?.customerCards
  let custId = values?.customerId

  if (!values) {
    const {
      data: {customerProfile: user},
    } = await vergentService.get<DetailsResponse>(customerDetailsEndpoint, {
      params: {
        maxNumberOfLoanRecords: 1,
      },
    })

    oneTimePaymentCards = user.cards
    custId = user.customerId
  }

  oneTimePaymentCards = oneTimePaymentCards?.filter(
    card => card.isOneTimeDebitCard,
  )

  if (!oneTimePaymentCards || oneTimePaymentCards.length === 0) return

  await Promise.all(
    oneTimePaymentCards.map(async oneTimePaymentCard => {
      await vergentService.put(
        `/VergentLMS/DisableCustomerCard/${String(
          oneTimePaymentCard.id,
        )}/${String(custId)}`,
      )
    }),
  )
}

interface ReactivationInitialDataArgs {
  reactivationInitialData: ReactivationCreateBody | undefined
}
/**
 * Makes a request for confirm create loan
 * @param reactivationInitialData reactivation details
 */
export async function reactivationInitialConfirmation({
  reactivationInitialData,
}: ReactivationInitialDataArgs) {
  if (reactivationInitialData) {
    const {data: postApplicationData} = await vergentService.post<
      ReactivationPostApplicationDetails
    >(`/VergentApplication/v2/${reactivationInitialData.applicationId ?? 0}`, {
      disbursementType: reactivationInitialData.disbursementType,
      primaryApplicant: reactivationInitialData.primaryApplicant,
    })

    if (!postApplicationData.success) {
      return undefined
    }

    let initialSubmitData: ReactivationInitialConfirmationResponse = {
      isApproved: false,
      portalTokenUrl: '',
    }

    try {
      const {data} = await vergentService.post<
        ReactivationInitialConfirmationResponse
      >(
        `/VergentApplication/${
          reactivationInitialData.applicationId ?? 0
        }/initialSubmit`,
      )
      initialSubmitData = data
      // eslint-disable-next-line no-empty
    } catch {}

    const {data: offerData} = await vergentService.post<OfferDataResponse>(
      `/VergentApplicationDecision/application/${
        reactivationInitialData.applicationId ?? ''
      }/decision/full`,
    )

    const offers: OfferData[] = await Promise.all(
      offerData.offers.map(async offer => {
        const {data: amountData} = await vergentService.get<AmountsResponse>(
          `/VergentApplicationLoanModel/${
            reactivationInitialData.applicationId ?? ''
          }/loan_models/${offer.loanModelId}/Amounts`,
        )
        return {
          id: offer.loanModelId,
          name: offer.displayName,
          range: {
            min: amountData.minMaxRanges[0].minimum,
            max: amountData.minMaxRanges[0].maximum,
          },
        }
      }),
    )

    setReactivationOffers(offers)

    return initialSubmitData
  }
  return undefined
}

interface ReactivationCreateLoanArgs {
  customerId: number
}

/**
 * Makes request to create a loan
 * @return application id and user details
 * @param customerId customer id
 */
export async function reactivationCreateLoan(customerId: number) {
  const {data: createData} = await vergentService.post<
    PostReactivationCreateResponse
  >(
    'VergentApplication/v2/Create',
    {},
    {
      params: {
        customerId,
      },
    },
  )
  await vergentService.post<ReactivationSyncResponse>(
    `VergentApplicationCustomer/${createData.responseToken}/sync`,
  )

  const {data} = await vergentService.get<ReactivationCreateBody>(
    `VergentApplication/v2/${createData.responseToken}`,
  )

  return {data, createData}
}
interface AddCustomerCardArgs {
  values: CreateCreditCardFormValues
  cardTypes: object
  isOneTimePaymentCard: boolean
}
/**
 * Makes request to add a customer card
 * @param values and card types
 * @return payment id
 */
export async function addCustomerCard({
  values,
  cardTypes,
  isOneTimePaymentCard,
}: AddCustomerCardArgs) {
  const creditCardsBody: CreditCardRequest = {
    lastFourCardNumber: values.cardNumber.slice(-4),
    cardReference: values.cardReference,
    cardHolderName: values.nameOnCard,
    authToken: localStorage.getItem(LocalStorageKeys.AccessToken) ?? '',
    cardType: getTypeCreditCard(values.cardType, cardTypes),
    expireMonth: Number(values.cardDateExpiry.slice(0, 2)),
    expireYear: Number(values.cardDateExpiry.slice(-4)),
    isEligibleForDisbursement: values.isEligibleForDisbursement ?? false,
    isOneTimeDebitCard: isOneTimePaymentCard,
  }

  try {
    const {data} = await vergentService.post<PostPaymentResponse>(
      'VergentCustomer/Customer/Cards',
      creditCardsBody,
    )

    return {
      data,
      values,
      newCard: {
        id: data.paymentId,
        cardTypeId: getTypeCreditCard(values.cardType, cardTypes),
        cardType: values.cardType ?? '',
        accountNumberMasked: `****${values.cardNumber.slice(-4)}`,
        expirationMonth: Number(values.cardDateExpiry.slice(0, 2)),
        expirationYear: Number(values.cardDateExpiry.slice(-4)),
        isExpired: false,
        isEligibleForDisbursement: values.isEligibleForDisbursement,
        cardReference: values.cardReference,
        isOneTimeDebitCard: isOneTimePaymentCard,
      },
    }
  } catch (error) {
    console.error(error)
    return null
  }
}
interface GetLoanScheduleRequestTwoProps {
  valuesToLoanEstimate: ConfirmRefinanceLoanValues
  payload: GetLoanScheduleValues
}

const buildSchedule = (paymentSchedule: PaymentSchedule[]) =>
  paymentSchedule.map(schedule => ({
    dueAmount: schedule.payment,
    dueDate: schedule.dueDate,
    graceDueDate: schedule.dueDate,
  }))

/**
 * To get the schedule for Refi , Cashback and Reactivation
 * @param valuesToLoanEstimate the payment
 * @return PostPaymentResponse
 */
export async function getLoanScheduleRequest({
  valuesToLoanEstimate,
  payload,
}: GetLoanScheduleRequestTwoProps) {
  const {data} = await vergentService.post<GetRefinanceEstimateResponse>(
    `/VergentLoans/Loans/LoanEstimate`,
    valuesToLoanEstimate,
  )

  const scheduledTotalPaymentAmount = data.paymentSchedule.reduce(
    (totalPayment, schedule) => totalPayment + schedule.payment,
    0,
  )

  let amountFinanced = data.loanAmount ?? 0

  if (payload.isReactivation) {
    amountFinanced = valuesToLoanEstimate.loanAmount
  }

  const financeCharge = data.financeCharge ?? 0

  const loanScheduleData: LoanScheduleResponse = {
    tilaDisclosureDetailsDto: {
      apr: data.apr ?? 0,
      financeCharge,
      amountFinanced,
      scheduledTotalPaymentAmount,
      firstPaymentDate: data.firstDueDate,
    },

    loanSchedule: buildSchedule(data.paymentSchedule),
  }

  return {
    ...loanScheduleData,
  }
}

/**
 * Makes request to get payment schedule value
 * @param applicationId application id
 */
export async function getReactivationPaymentSchedule({
  applicationId,
  loanModelId,
  amount,
}: GetReactivationPaymentScheduleArgs) {
  const fundingMethod = 3

  const {data} = await vergentService.get<
    GetReactivationPaymentScheduleResponse
  >(
    `/VergentApplicationLoanModel/${applicationId}/loan_models/${loanModelId}/Amounts/${amount}/FundingMethods/${fundingMethod}/Values`,
  )

  const scheduledTotalPaymentAmount =
    data.paymentSchedule?.reduce(
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      (totalPayment, schedule) => totalPayment + schedule.payment,
      0,
    ) ?? 0

  const loanScheduleData: LoanScheduleResponse = {
    tilaDisclosureDetailsDto: {
      apr: data.apr,
      financeCharge: data.feesAmount,
      amountFinanced: amount,
      scheduledTotalPaymentAmount,
      firstPaymentDate: data.firstDueDate,
    },
    loanSchedule: buildSchedule(data.paymentSchedule ?? []),
  }

  return {...loanScheduleData}
}

/**
 * Takes a form submission and returns a url to add a card or make a payment
 * @param customerId customer id
 * @param mobileProfileId mobile prfoile id
 * @param state store state abreviation
 * @param amount if 0 will add the debit card to the customer profile and if greater than 0 this function will be use to make payments
 * @return debit url
 */
export async function getDebitCardFormUrl(
  customerId: string,
  mobileProfileId: number | undefined,
  state: string,
  amount: number | undefined = 0,
) {
  const {data} = await vergentService.get<DebitCardUrl>(
    'VergentPayment/Payments/AddCardUrl',
    {
      params: {
        CustomerId: customerId,
        MobileProfileId: mobileProfileId,
        StoreState: state,
        Amount: amount,
      },
      headers: isIE11 ? {Pragma: 'no-cache'} : undefined,
    },
  )

  return data
}

const MAX_ATTEMPTS = 3
const DELAY = 5000

const delay = async () =>
  new Promise<void>(resolve =>
    setTimeout(() => {
      resolve()
    }, DELAY),
  )

interface StartRefinanceApplicationArgs extends ConfirmRefinanceLoanValues {
  attempts?: number
}

/**
 * Makes request for starting application of type cashback/refinance
 * @param values the payment info submitted by customer, and the options they've selected
 * @return ConfirmRefinanceLoanResponse
 */
export async function startRefinanceApplication({
  attempts,
  ...values
}: StartRefinanceApplicationArgs): Promise<{
  documentUrl: string
  paymentResult: {
    newLoanId: number
  }
}> {
  const totalAttempts = attempts ?? 1

  try {
    const {data: refi} = await vergentService.post<{
      refinanceResult: {
        loanHeader: {
          hdr_id: number
        }
      }
    }>(`VergentLoans/Refinance`, values)

    const {data: document} = await vergentService.get<DocumentResponse>(
      `VergentDocument/Customer/Loans/${refi.refinanceResult.loanHeader.hdr_id}/Documents/eSign`,
    )

    return {
      documentUrl: document.eSignUrl,
      paymentResult: {
        newLoanId: refi.refinanceResult.loanHeader.hdr_id,
      },
    }
  } catch (error) {
    if (totalAttempts < MAX_ATTEMPTS) {
      await delay()
      return await startRefinanceApplication({
        ...values,
        attempts: totalAttempts + 1,
      })
    }
    throw error
  }
}

interface StartRefinanceCashBackApplicationArgs
  extends ConfirmRefinanceCashbackLoanValues {
  attempts?: number
}

/**
 * Makes request for starting application of type cashback/refinance
 * @param values the payment info submitted by customer, and the options they've selected
 * @return ConfirmRefinanceLoanResponse
 */
export async function startRefinanceCashBackApplication({
  attempts,
  ...values
}: StartRefinanceCashBackApplicationArgs): Promise<{
  documentUrl: string
  paymentResult: {
    newLoanId: number
  }
}> {
  const totalAttempts = attempts ?? 1

  try {
    const {data: refi} = await vergentService.post<{
      originateLoanResponse: {
        loanHeader: {
          hdr_id: number
        }
      }
    }>(`VergentLoans/CashbackRefinance`, values)

    const {data: document} = await vergentService.get<DocumentResponse>(
      `VergentDocument/Customer/Loans/${refi.originateLoanResponse.loanHeader.hdr_id}/Documents/eSign`,
    )

    return {
      documentUrl: document.eSignUrl,
      paymentResult: {
        newLoanId: refi.originateLoanResponse.loanHeader.hdr_id,
      },
    }
  } catch (error) {
    if (totalAttempts < MAX_ATTEMPTS) {
      await delay()
      return await startRefinanceCashBackApplication({
        ...values,
        attempts: totalAttempts + 1,
      })
    }
    throw error
  }
}

interface GetDocumentForSigningArgs {
  loanHeaderId: number
  attempts?: number
}

/**
 * Get document
 * @param loanHeaderId header id
 */
export const getDocumentForSigning = async ({
  attempts,
  loanHeaderId,
}: GetDocumentForSigningArgs): Promise<DocumentResponse> => {
  const totalAttempts = attempts ?? 1
  try {
    const {data: document} = await vergentService.get<DocumentResponse>(
      `VergentDocument/Customer/Loans/${loanHeaderId}/Documents/eSign`,
    )

    return document
  } catch (error) {
    if (totalAttempts < MAX_ATTEMPTS) {
      await delay()
      return await getDocumentForSigning({
        loanHeaderId,
        attempts: totalAttempts + 1,
      })
    }
    throw error
  }
}

/**
 * StartReactivationApplicationArgs
 */
export interface StartReactivationApplicationArgs
  extends ReactivationSubmitPayload {
  applicationId: string
  attempts?: number
}

/**
 * Makes request for starting application of type reactivation
 * @param values the payment info submitted by customer
 * @return PostPaymentResponse
 */
export async function startReactivationApplication({
  applicationId,
  attempts,
  ...reactivationSubmitPayload
}: StartReactivationApplicationArgs): Promise<{
  documentUrl: string
  paymentResult: {
    newLoanId: number
  }
}> {
  const totalAttempts = attempts ?? 1

  try {
    const {data: reactivationSubmitData} = await vergentService.post<
      ReactivationSubmitResponse
    >(`/VergentApplication/${applicationId}/submit`, reactivationSubmitPayload)

    return {
      documentUrl: reactivationSubmitData.eSignUrl,
      paymentResult: {
        newLoanId: reactivationSubmitData.loanHeaderId,
      },
    }
  } catch (error) {
    if (totalAttempts < MAX_ATTEMPTS) {
      await delay()
      return await startReactivationApplication({
        applicationId,
        ...reactivationSubmitPayload,
        attempts: totalAttempts + 1,
      })
    }
    throw error
  }
}

/**
 * Submits final application for refinance
 * @param values gathered data from refinance flow
 * @return RefinanceLoanResponse
 */
export async function completeRefinanceApplication(
  values: RefinanceLoanValues,
) {
  const {data} = await axios.post<RefinanceLoanResponse>(
    `${edgeUrl}/loans/complete-refinance`,
    values,
  )
  return data
}

/**
 * Submits final application for reactivation
 * @param values gathered data from refinance flow
 * @return OriginateLoanResponse
 */
export async function completeReactivationApplication(
  values: OriginateLoanValues,
) {
  const {data} = await axios.post<OriginateLoanResponse>(
    `${edgeUrl}/loans/complete-reactivate`,
    values,
  )
  return data
}

/**
 * Timeout for a specific period
 * @param milliseconds time for the timeout
 * @return Timeout promise
 */
async function timeout(milliseconds: number) {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

/**
 * Submits final application for refinance with a delay
 * @param values gathered data from refinance flow
 * @return RefinanceLoanResponse
 */
export async function completeRefinanceApplicationWithDelay(
  values: RefinanceLoanValues,
) {
  try {
    await timeout(serviceCallDelay)

    const {
      data: {loanTransactionHistoryList: newLoanHistory} = {},
    } = await vergentService.get<LoanTransactionResponse>(
      `/VergentLoans/Loans/${values.newLoanId}/Transactions`,
    )
    const {
      data: {loanTransactionHistoryList: prevLoanHistory} = {},
    } = await vergentService.get<LoanTransactionResponse>(
      `/VergentLoans/Loans/${values.previousLoanId}/Transactions`,
    )

    const prevLoanTransaction = prevLoanHistory?.find(({typeName}) =>
      ['Refinance'].includes(typeName),
    )
    const getTransactions = () => {
      const transaction = prevLoanHistory?.find(({typeName}) =>
        typeName.includes('Refinance - Card (Auto)'),
      )
      return transaction
        ? [
            {
              label: 'Finance Charges*',
              amount: transaction.total,
            },
          ]
        : [
            {
              label: 'Finance Charges*',
              amount: values.refiPaymentDetails.paymentAmount,
            },
          ]
    }
    const refiPaymentReceiptDto: RefiPaymentReceipt = {
      currentBalance: newLoanHistory?.[newLoanHistory.length - 1].balance ?? 0,
      paymentAmount: values.refiPaymentDetails.paymentAmount, //,
      transactions: getTransactions(),
    }

    const {
      data: {documents},
    } = await vergentService.get<{documents: DownloadDocuments[]}>(
      `/VergentLoans/Loans/Documents`,
      {
        params: {
          CustomerId: values.customerId,
          LoanHeaderId: values.newLoanId,
        },
      },
    )
    const data: RefinanceLoanResponse = {
      loanId: values.newLoanId,
      refiPayOutProcessedDebitcardResponseDto: {
        authorizationNumber:
          prevLoanTransaction?.transactionItemId.toString() ?? '',
        debitCardLast4:
          values?.refiCashOutPaymentDetails?.debitCardLast4 ?? '',
        isDebitCardProcessedSuccessfully: true,
      },
      signedLoanAgreementDocumentUrl: documents,
      refiPaymentReceiptDto,
      refiPaymentProcessedDebitcardResponseDto: {
        authorizationNumber:
          prevLoanTransaction?.transactionItemId.toString() ?? '',
        debitCardLast4: values.refiPaymentDetails.debitCardLast4,
        isDebitCardProcessedSuccessfully: false,
      },
    }

    // eslint-disable-next-line no-console
    console.log('completeRefinanceApplicationWithDelay', data)
    return data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log({error})
    return null
  }
}

/**
 * CompleteReactivationApplicationWithDelayArgs
 */
export interface CompleteReactivationApplicationWithDelayArgs {
  newLoanId: number
  prevLoanId: number
  customerId: string
}

/**
 * FundLoanResponse object
 */
export interface FundLoanResponse {
  fundLoanResult: {
    message: string
    errors: string[]
    httpStatusCode: number
    fundLoanResultStatus: string
    loanId: number
    errorMessage: string
  }
}

/**
 * POST FundLoan
 * @param loanId Id of the loan
 * @param customerId Id of the customer
 */
export async function postFundLoan(loanId: number, customerId: string) {
  const {data: fundLoanData} = await vergentService.post<FundLoanResponse>(
    `/VergentLoans/Loans/${loanId}/FundLoan`,
    {},
    {
      params: {
        customerId,
      },
    },
  )

  return fundLoanData
}

/**
 * Submits final application for reactivation with a delay
 * @param values gathered data from refinance flow
 * @return OriginateLoanResponse
 */
export async function completeReactivationApplicationWithDelay(
  values: CompleteReactivationApplicationWithDelayArgs,
) {
  const {data: fundLoanData} = await vergentService.post<FundLoanResponse>(
    `/VergentLoans/Loans/${values.newLoanId}/FundLoan`,
    {},
    {
      params: {
        customerId: values.customerId,
      },
    },
  )
  const {
    data: {documents},
  } = await vergentService.get<{documents: DownloadDocuments[]}>(
    `/VergentLoans/Loans/Documents`,
    {
      params: {
        CustomerId: values.customerId,
        LoanHeaderId: values.newLoanId,
      },
    },
  )

  if (fundLoanData.fundLoanResult.fundLoanResultStatus !== 'Success')
    throw new Error(fundLoanData.fundLoanResult.errorMessage)

  const {
    data: {loanTransactionHistoryList: prevLoanHistory} = {},
  } = await vergentService.get<LoanTransactionResponse>(
    `/VergentLoans/Loans/${values.prevLoanId}/Transactions`,
  )

  const prevLoanTransaction = prevLoanHistory?.find(({typeName}) =>
    typeName.includes('New Loan'),
  )

  const data: OriginateLoanResponse = {
    loanId: values.newLoanId,
    payOutPaymentProcessedDebitcardResponseDto: {
      authorizationNumber:
        prevLoanTransaction?.transactionItemId.toString() ?? '',
      debitCardLast4: '',
      isDebitCardProcessedSuccessfully: true,
    },
    message: '',
    responseCode: 0,
    signedLoanAgreementDocumentUrl: documents,
  }

  return {...data}
}

/**
 * Logs Error To Application Insights
 * @param message The error message
 * @return string
 */
export async function logErrorToApplicationInsights(
  message: string | undefined,
) {
  try {
    const errorMessage =
      message === undefined ? 'Missing error message' : message

    const {data} = await vergentService.post<string>(
      `/VergentTelemetry/log-error`,
      `"${errorMessage}"`,
      {
        headers: {'Content-type': 'application/json'},
      },
    )
    return data
  } catch (error) {
    console.error(error)
    return null
  }
}

/**
 * Takes a form submission and returns validation for CashWiseInformationRequest
 * @param values the info submitted to the form on CashWiseCustomer landing page
 * @return CashWiseInformationResponse if CashWiseInformationRequest values are valid
 */
export async function validateCashWiseInfo(
  values: CashWiseInformationRequest,
) {
  const {data} = await customerPortalService.post<CashWiseInformationResponse>(
    `/Customer/cashwise-info`,
    values,
  )
  return data
}

/**
 * Calls the health-check endpoint on the Edge service.
 * @return HealthCheckResponse
 */
export async function getHealthCheckData() {
  try {
    const {data} = await vergentService.get<HealthCheckResponse>(
      `VergentTelemetry/health-check`,
    )
    return data
  } catch (error) {
    return {
      status: 'Error',
      isHealthy: false,
      info: [
        {
          status: 'Error',
          error: (error as Error).message,
        },
      ],
      name: '',
    }
  }
}

/**
 * Calls the requestPinByText endpoint on the Edge service.
 * @return HealthCheckResponse
 * @param values requestPinByText
 */
export async function getRequestPinByText(values: RequestPinByTextBody) {
  try {
    const {data} = await vergentService.post<RequestPinByTextResponse>(
      `/VergentCommunications/RequestPinByText`,
      values,
    )
    return data
  } catch (error) {
    return null
  }
}

/**
 * Calls the requestPinByText endpoint on the Edge service.
 * @return HealthCheckResponse
 * @param values requestPinByText
 */
export async function verifyPin(values: VerifyPinRequest) {
  try {
    const {data} = await vergentService.post<VerifyPinResponse>(
      `/VergentCommunications/VerifyPin`,
      values,
    )
    return data
  } catch (error) {
    return null
  }
}

/**
 * Calls all documents to download
 * @return DownloadDocuments
 * @param customerId customer id
 * @param newLoanId new loan id
 */
export async function getDownloadDocuments(
  customerId: number,
  newLoanId: number,
) {
  const {
    data: {documents},
  } = await vergentService.get<{documents: DownloadDocuments[]}>(
    `/VergentLoans/Loans/Documents`,
    {
      params: {
        CustomerId: customerId,
        LoanHeaderId: newLoanId,
      },
    },
  )

  return documents
}

/**
 * Calls if the documents were signed
 * @return GetSigningStatus
 * @param newLoanId new loan id
 */
export async function getSigningStatus(newLoanId: number) {
  const {data} = await vergentService.get<GetSigningStatus>(
    `/VergentLoans/GetSigningStatus`,
    {
      params: {
        hdrId: newLoanId,
      },
    },
  )

  return data
}

/**
 * Calls all documents to download for loans in previous year
 * @return MyAccountDownloadDocuments
 * @param customerId customer id
 */
export async function getDownloadDocumentsForLoansWithinLastyear(
  customerId: number,
) {
  const getFullLoansQueryParams: GetFullLoansQueryParams = {
    customerId: Number(customerId),
  }
  const {data: fullLoansData} = await vergentService.get<
    CustomerLoanDetailResponse
  >('/VergentLoans/Customer/Loans/Full', {
    params: getFullLoansQueryParams,
  })

  const responseWithUniqueLoanIds = new Map()
  fullLoansData.fullProcessInfoModels.forEach(loan => {
    if (
      loan.publicLoanId &&
      loan.loanStatus !== 'Deleted' &&
      !responseWithUniqueLoanIds.has(loan.publicLoanId)
    ) {
      responseWithUniqueLoanIds.set(loan.publicLoanId, loan)
    }
  })

  const configurableDate = getConfigValue('LOAN_DOCUMENT_HISTORY_START_DATE')
    ? new Date(
        `${getConfigValue('LOAN_DOCUMENT_HISTORY_START_DATE')}T00:00:00-05:00`,
      )
    : null
  const startDate = configurableDate ?? new Date()
  if (!configurableDate) {
    startDate.setFullYear(startDate.getFullYear() - 1)
  }

  const getPreviousYearLoans: FullProcessInfoModel[] = Array.from(
    responseWithUniqueLoanIds.values(),
  ).filter(
    loan =>
      new Date(loan.originationDate) >= startDate &&
      new Date(loan.originationDate) <= new Date(),
  )

  const documents = getPreviousYearLoans.map(async loan => {
    const response = await getDownloadDocuments(
      Number(customerId),
      Number(loan.loanHeaderId),
    )
    return response.map(
      (
        doc: Omit<MyAccountDownloadDocuments, 'loanId' | 'originationDate'>,
      ) => ({
        ...doc,
        loanId: loan.publicLoanId,
        originationDate: loan.originationDate,
      }),
    )
  })

  const completeDocuments = await Promise.all(documents)

  return completeDocuments
    .flat()
    .slice()
    .sort(
      (a, b) =>
        new Date(b.originationDate).getTime() -
        new Date(a.originationDate).getTime(),
    )
}
